import React, {Component} from 'react'
import {Form
    , FormGroup
    , FormControl
    , InputGroup
    //, FormLabel
    , FormText } from 'react-bootstrap'
import {styles} from './styles.js'

import {library} from '@fortawesome/fontawesome-svg-core';
//import { fab }   from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

library.add(faUser);

export default class SigninUserName extends Component {
    constructor() {
        super();
        this.handleUserNameBlur = this.handleUserNameBlur.bind(this);
        this.handleUserNameChange = this.handleUserNameChange.bind(this);
        this.state = {
            userNameError: null
            ,userNameState: null
        }
    }

    handleUserNameBlur(e){
        var userName = e.target.value;
        var stateVal = ( userName === '' || userName == null ) ?
             {userNameError: "User name cannot be blank!", userNameState: "error"}
            :{userNameError: null, userNameState: "success"};
        this.setState(stateVal);
        this.props.onBlur({fieldName: "userName"
                        , fieldValue: userName
                        , fieldState: stateVal.userNameState});
        // console.log("SigninUserName.handleUserNameBlur.userName:", e.target.value
        //  , " state:", this.state);
    }
    handleUserNameChange(e){
        //var userName = e.target.value;
        this.setState({userNameError: null, userNameState: null});
    }

    render() {
        return <FormGroup controlId={this.props.controlId} 
                          valid={this.state.userNameState}>
            <Form.Text>User Name:</Form.Text>

        <InputGroup className="mb-0">
            <InputGroup.Prepend>
            <InputGroup.Text>
             <FontAwesomeIcon icon="user" />
             </InputGroup.Text>
          </InputGroup.Prepend>
            <FormControl type="text" 
                name={this.props.name} 
                onBlur={this.handleUserNameBlur}
                onChange={this.handleUserNameChange}
                autoComplete="username" 
                placeholder="Enter your name" 
                isInvalid={this.state.userNameState === "error"}
                isValid={this.state.userNameState === "success"}
                required />
        </InputGroup>
            <FormControl.Feedback/>
            {this.state.userNameError != null && <FormText style={styles.helpBlock}>* {this.state.userNameError}</FormText>}

        </FormGroup>
    }
}