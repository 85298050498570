import React, {Component} from 'react';
import {
    Col
    ,Card
    ,Badge
    ,Button
} from 'react-bootstrap';

import {videoStyles} from './videoStyles.js' 
import VideoPanel from './VideoPanel.js';

export default class VideoScreenShare extends Component {
    constructor() {
        super();
        this.handleScreenShareClick = this.handleScreenShareClick.bind(this);
        this.getDisplayMediaSuccess = this.getDisplayMediaSuccess.bind(this);
        this.state = {
            shareScreen: false
        };
    }

    handleScreenShareClick(e) {
        console.log('VideoScreenShare.handleScreenShareClick().');
        this.setState ({shareScreen: true});
    }
    getDisplayMediaSuccess(stream) {
        console.log('videoScreenShare.getDisplayMediaSuccess');
        //this.refs.videoScreenShareRef.srcObject = stream;
        document.getElementById("videoScreenShareId").srcObject = stream;

        // Make sure if the chrome 'end share' button clicked, it syncs gnetchat sharing swith too.
        stream.getVideoTracks()[0].addEventListener('ended', () => this.props.shareScreenEnd() );
    }

    errorHandler(err){
        console.log('*** videoScreenShare.errorHandler.err:', err);
    }

    render() {
        if( this.props.shareScreen && navigator.mediaDevices.getDisplayMedia) {
            navigator.mediaDevices.getDisplayMedia({video:true})
                .then(this.getDisplayMediaSuccess).catch(this.errorHandler);
        } 
        
        // catch(e) {
        //     console.log('VideoScreenShare unable to aquire screen capture.e:',e);
        // }
        // var mdGrid = {span:8, offset:0}
        // var lgGrid = {span:6, offset:0}
        // var xlGrid = {span:4, offset:0}

        //return <div>{ this.state.shareScreen &&
        // return <Col key={3}  sm={12} 
        //       md={mdGrid} lg={lgGrid} xl={xlGrid}
        //       style={videoStyles.padding0Style}>
        // <Card bsstyle="success" style={videoStyles.panelStyle}>
        // {/* <Badge variant="primary">Share</Badge> */}
        // <Card.Body style={videoStyles.padding0Style}> 
        return <div className="embed-responsive embed-responsive-4by3" style={{width:28, height:21}}>
           <video id="videoScreenShareId" className="embed-responsive-item" ref="videoScreenShareRef" 
           autoPlay muted playsInline controls={false} 
           style={{width:'100%'}} > </video>
        </div>;
        // </Card.Body>
        // </Card>  
        // </Col>;
        //<Button id="hiddenShareButton" onClick={this.handleScreenShareClick} ></Button>

        //</div>;
    }
}