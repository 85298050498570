import React, {Component} from 'react'

import {Modal
    , Button
    // , Checkbox
    // , FormText
    , Form
    , FormGroup
    , FormControl
    , FormLabel
    } from 'react-bootstrap'
import {styles} from './styles.js'
import {Auth} from "aws-amplify";

//var axios = require('axios');

export default class ConfirmReset extends Component{
    constructor(){
        super();
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleForgetPassword = this.handleForgetPassword.bind(this);
        this.handleResendCode = this.handleResendCode.bind(this);
        this.state = {
            show: false,
            code: '',
            password: '',
            codeError: null
        }
    }
    handleModalClose(){
        this.setState({show:false});
    }
    handleModalShow() {
        this.setState({show:true});
    }
    handleCodeChange(e) {
        this.setState({code: e.target.value});
    }
    handlePasswordChange(e) {
        this.setState({password: e.target.value});
    }
    handleForgetPassword(evt){
        console.log("handleForgetPassword.evt:",evt ); 

        this.setState({show:true});
        evt.preventDefault();
        //const form = evt.currentTarget;

        var myComp = this;
        Auth.forgotPasswordSubmit(this.props.email
            , this.state.code
            , this.state.password)
        .then ( function(data){
            //console.log("ConfirmReset.handleForgetPassowrd.data:", data, " successfull!");
            // Call parent function to process reset success event.
            myComp.props.resetSuccess(true, myComp.state.password);
            // Reset password successful. Close modal form.
            myComp.setState({show:false}); 
        } )
        .catch( function(err){
            var msg = err.name + ": " + err.message;
            console.log("ConfirmReset.handleForgetPassword.err:", err, " msg:", msg );
            myComp.setState({codeError: msg} );
        } );
    }
    handleResendCode() {
        console.log("ConfirmReset.handleResendCode.");
        Auth.forgotPassword(this.state.email)
        .then ( function(data){
            console.log("ConfirmReset.handleResendCode.data:", data);
        } )
        .catch( function(err){
            console.log("ConfirmReset.handleResentCode.err:", err);
        } );
    }
    render(){
        this.props.setShow(this.handleModalShow);
        //this.state.email = this.props.email;

        return <Modal show={this.state.show} onHide={this.handleModalClose}>
        <Modal.Header closeButton>
            <Modal.Title>Reset Password for {this.props.email}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={styles.modalBodyStyle}>
        <Form onSubmit={this.handleForgetPassword} >        
          <FormGroup controlId="resetFormCode">
          <FormLabel>Check your email inbox and enter code:</FormLabel>
          <FormControl type="code" 
                       name="code" 
                       value={this.state.code}
                       onChange={this.handleCodeChange}
                       autoComplete="code" 
                       placeholder="XXXXXX" />
           {this.state.codeError != null && <Form.Text style={styles.helpBlock}>* {this.state.codeError}</Form.Text>}
          </FormGroup>
          <FormGroup>
          <FormLabel>Enter the new password below:</FormLabel>
          <FormControl type="password" 
                       name="password" 
                       value={this.state.password}
                       onChange={this.handlePasswordChange}
                       placeholder="Enter new password" />
          </FormGroup>

          <Modal.Footer style={styles.modalFooterStyle}>
              <Button onClick={this.handleResendCode}>Resend Code</Button>
              <Button type="submit" value="RESET" 
                    disabled={this.state.code === '' || this.state.password === ''}>Confirm New Password</Button>
          </Modal.Footer>
        </Form>
        </Modal.Body>
        </Modal>;
    }
}