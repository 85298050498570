import React
  , { useState } from 'react'
import {
     ToggleButton
    ,ToggleButtonGroup
} from 'react-bootstrap';

import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faMicrophone 
  , faMicrophoneSlash
  , faVideo 
  , faVideoSlash
  , faSlash
  , faDesktop } from '@fortawesome/free-solid-svg-icons';

import {styles} from "./login/styles.js"

library.add(faMicrophone
    , faMicrophoneSlash
    , faVideo
    , faVideoSlash
    , faSlash
    , faDesktop);

// audio or video disable/enable toggle buttons.
// export default class AvToggle extends Component {
//   constructor() {
//       super();

//       //const [active, setActive] = useState(0);
//   }

const AvToggle = (props) => { 
    // value is 0 or 1.
    const [value, setValue] = useState(0);

    var toggleChanged = function(val) {
      // val is an array, [0] or []
      setValue(val.length);
      console.log("AvToogle.val.len:",val.length);
    }

    return <ToggleButtonGroup 
             name={props.name} 
             type="checkbox" 
             onChange={toggleChanged}
             width={styles.avwidth}>
       <ToggleButton variant={ value === 0 ? "danger" : "success"} 
        //style={styles.autoCursor} 
        //size="sm" 
        style={ value === 0 ? props.slashPadding : props.successPadding }
        value={0} width={styles.avwidth}>   
        <FontAwesomeIcon icon={value === 0 ? props.iconSlash : props.iconName} /> 
       
      </ToggleButton>
      </ToggleButtonGroup>;
}

  //   return <ToggleButtonGroup 
  //         name={props.name}
  //         type="radio" 
  //         value={value}
  //         //onChange={ (val) => setValue(val)} 
  //         //onClick={ clickIt} 
  //         >
  //     <ToggleButton variant={ value === 0 ? "danger" : "link"} 
  //       style={styles.autoCursor} 
  //       size="sm" 
        
  //       name={props.name}
  //       value={0} 
  //       onClick={clickIt}
  //         >   
  //       <FontAwesomeIcon icon={props.iconSlash} />
  //     </ToggleButton>
  //     <ToggleButton variant={ value === 0 ? "link" : "primary"} 
  //       size="sm"
        
  //       name={props.name}
  //       value={1} 
  //       onClick={clickIt}
  //       >
  //        <FontAwesomeIcon icon={props.iconName} />
  //     </ToggleButton>
  // </ToggleButtonGroup> ;
  // }
//}

export default AvToggle;