export const videoStyles = {

    panelStyle: {
        border: '0'
        ,margin: '0'
        ,display: 'contents'
    }
    // var panelHeadingStyle = {
    //     padding: '0px'
    //     ,border: '0'
    // };
    ,containerStyle: {
        'paddingTop': '77px'
        ,'paddingLeft': '0'
        ,'paddingRight': '0'
        ,width: '100vw'
    }
    ,padding0Style: {
        padding: '0'
    }
    // var padding1Style = {
    //     padding: '1px'
    // };
    ,padding1Hide: {
        padding: '1px'
        // ,visibility: 'hidden'
        ,display: 'none'
        //,border: '1px'
        // ,'borderColor': 'blue'
        //,'backgroundColor': 'coral'
    }
    ,margin0Style: {
        margin: '0'
    }
    ,slashPadding: {
        'paddingLeft': '12px'
        ,'paddingRight': '12px'
    }
    ,successVideo: {
        'paddingLeft': '13px'
        ,'paddingRight':'13px'
    }
    ,successAudio: {
        'paddingLeft': '17px'
        ,'paddingRight': '16px'
    }
};