import React, {Component} from 'react';
import {Button,Form, FormGroup //, Navbar
      , FormControl, Modal}
    from 'react-bootstrap';

import {library} from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';

library.add(faNetworkWired);

var axios = require('axios');

const Events = require('events');
const myEvents = new Events();
var myInfo = require('./MyInfo.js');


export default class SearchModal extends Component {
    constructor(){
        super();
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
        this.updateText = this.updateText.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.searchEmail = this.searchEmail.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.connectToTarget = this.connectToTarget.bind(this);
        this.targetEmail = this.targetEmail.bind(this);
        this.checkingCloseDialog = this.checkingCloseDialog.bind(this);
        this.state = {
            inText: ''
            ,retEmails:[]
            ,targetEmail:''
            ,show: false
        }
    }
    handleButton(type) {
        console.log("handleButton.inText", this.state.inText, " type:", type);
        this.props[type](this.state.inText);
    }
    handleModalClose() {
        this.setState({show:false});
    }
    handleModalShow() {
        this.setState({show:true});
    }
    updateText(event) {
        console.log('SearchModal.updateText:', event.target.value);
        this.setState({inText: event.target.value});
        this.setState({targetEmail: event.target.value});
    }
    handleKeyPress(e) {
        if( e.key === 'Enter'){
            if( this.state.inText) {
                //this.searchEmail();
                this.connectToTarget()
            }
        }
    }
    searchEmail() {
        var userId = document.getElementById('userId').value;
        console.log("SearchForm.searchEmail", this.state.inText, " userId:", userId);
        //fetch('/searchByEmail');

        var component = this;
        //axios.get('/searchByEmail?email=' + this.state.inText)
        axios.get('/searchByEmail?userId=' + userId + '&email=' + this.state.inText)
        //fetch('/searchByEmail?userId=' + userId + '&email=' + this.state.inText)
          .then( function (response){
            var myData = response.data;
            var ttEmail = "";
            if( myData !== undefined && myData.length > 0) {
                // Set the default selection as the first item in the list.
                ttEmail = myData[0].email;
            }
            console.log("searchByEmail.response", myData, "ttEmail:", ttEmail, " component:",component);
            // show the result in a modal dialog.
            component.setState({retEmails: myData, show:true, targetEmail: ttEmail});
          })
          .catch( function (error){
            console.log("searchByEmail.error:",error);
          });
    }
    selectChange(event) {
        var targetValue = event.target.value;
        console.log('selectChange.value:',targetValue);
        this.setState({targetEmail: targetValue});
    }

    onConnect2Target(callback) {myEvents.on("connect2Target", callback);}
    offConnect2Target(callback) {myEvents.off("connect2Target", callback);}

    connectToTarget() {
        console.log("connectToTarget.targetEmail:", this.state.targetEmail);
        // Don't know the target toUuid just yet.
        var toUuid = '';
        myEvents.emit('connect2Target'
            , myInfo.myUuid()
            , myInfo.myEmail()
            , toUuid
            , this.state.targetEmail
            , 'connectionForm'
        );
    }
    targetEmail() {
        return this.state.targetEmail;
    }

    checkingCloseDialog( incomingEmail ) {
        // console.log("checkingCloseDialog.incomingEmail:", incomingEmail
        //     ," targetEmail:", this.state.targetEmail
        //     ," show:", this.state.show);
        // Close the search box if the target email connects back.
        if( this.state.targetEmail === incomingEmail 
            && this.state.show === true ){
                this.handleModalClose();
        }
    }

    render() {
        return     <Form inline>
 
          <FormControl value={this.state.inText} onChange={this.updateText} onKeyPress={this.handleKeyPress} 
                      type="text" placeholder="Enter email to connect"  className="mr-sm-2" />
        {/* <Button type="submit" onClick={this.searchEmail} disabled={!this.state.inText} variant="outline-info">Search</Button> */}
        <Button onClick={this.connectToTarget} disabled={!this.state.targetEmail}><FontAwesomeIcon icon="network-wired" /></Button>

        <Modal show={this.state.show} onHide={this.handleModalClose} > 
        <Modal.Header closeButton>
        <Modal.Title>{this.state.targetEmail ? ('Select an email and click Connect') : ('No email found. Retry please.' )}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <FormGroup>
        <FormControl componentClass="select" size={5} onChange={this.selectChange} multiple={false}>
                  {this.state.retEmails.map( item => <option value={item.email} key={item.email}>{item.email}</option>)}
        </FormControl>
        <Button onClick={this.connectToTarget} disabled={!this.state.targetEmail}>Connect</Button>
        </FormGroup>
        </Modal.Body>
        <Modal.Footer>
           <Button onClick={this.handleModalClose}>Close</Button>
        </Modal.Footer>
        </Modal>
      </Form>;
    }
}