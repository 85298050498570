import React, {Component} from 'react';
import {Container
  , Row
  , Col
  , Card
  , Badge
} from 'react-bootstrap'

import //PropTypes, 
    { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

//import {Button, Navbar, FormGroup, FormControl, Grid, Row, Col} from 'react-bootstrap';

//var TitleBar = require('./TitleBar');
import TitleBar from './TitleBar'
import LocalVideo from './LocalVideo.js';
import VideoScreenShare from './VideoScreenShare.js';

//import MySignin from "./login/MySignin.js"; 
import VideoPanel from './VideoPanel.js'

//var mysocket = require('./MySocket.js').default;
import mysocket from './MySocket';

import {videoStyles} from './videoStyles.js';

//var MyPeer = require('./MyPeer.js');
import MyPeer from './MyPeer.js';

// //var RemoteVideoList = require('./RemoteVideoList.js');
// import RemoteVideoList from './RemoteVideoList';
//var RemoteVideoStore = require('./RemoteVideoStore.js');
import RemoteVideoStore  from './RemoteVideoStore.js';


var axios = require('axios');
var myInfo = require('./MyInfo.js');


class Vchat extends Component {

    // getInitialState() {
    //   return {
    //     connected: false,
    //     remoteArgs: RemoteVideoStore.getRemoteArgs()
    //   }
    // }
    constructor(props) {
      super(props);
      //this.syncRemoteArgs = this.syncRemoteArgs.bind(this);

      this.state = {
        connected: false,
        remoteArgs: RemoteVideoStore.getRemoteArgs()
       };  
       //this.handleNewConn = this.handleNewConn.bind(this);
       //this.connectionFormRef = this.connectionFormRef.bind(this)
       console.log("Vchat.constructor().props:",props);

    }

    componentDidMount() {
      //RemoteVideoStore.onNewConn(this.processNewConn);
      // It only initialize the socket when the local video 
      // is opened successfully
      mysocket.constructSocket();
      this.refs.localVideoRef.onMediaSuccess(() => {this.initSocket(this);});
      console.log('vchat.componentWillMount');
      console.log("componentDidMount.connectionFormRef:", this.connectionFormRef);

    }
   componentWillUnmount() {
     console.log('vchat.componentWillUnmount');
     this.refs.localVideoRef.offMediaSuccess(() => {this.initSocket(this);});
     this.connectionFormRef.searchModal.offConnect2Target(mysocket.connect2Target);
     RemoteVideoStore.offNewConn((a,b) => {this.handleNewConn(a,b,this);});
     mysocket.offNewConn((a) => {this.handleNewConn(a,this);});
     mysocket.offSdp((a,b,c) => {this.handleSdp(a,b,c,this);});
     mysocket.close();
   }
   //rr is a remoteInfo object
   handleNewConn (rr, obj){
      console.log("Vchat.handleNewConn.rr:", rr);
      // Since it's a new connection, it needs to reset first.
      if(rr.email !== "" ) {
        RemoteVideoStore.resetArgByEmail(rr.email);
      }
      if(rr.uuid !== "") {
        RemoteVideoStore.resetArgByUuid(rr.uuid);
      }
      
      var pp = RemoteVideoStore.updateArgRetPeer(
          obj.refs.localVideoRef, mysocket, rr.uuid, rr.email);
      MyPeer.createOffer(pp, mysocket, rr.uuid, rr.email);
      console.log("Vchat.handleNewConn ended.");
   }
   initSocket(obj){
     //this.handleNewConn = this.handleNewConn.bind(this);
     //mysocket = MySocket();
     console.log("Vchat.initSocket.mysocket:",mysocket);
     
     mysocket.onNewConn((a) => {obj.handleNewConn(a,obj);});   
     mysocket.onSdp((a,b,c) => {obj.handleSdp(a,b,c,obj);}); 
     mysocket.onIce((a,b,c) => {obj.handleIce(a,b,c,obj);}); 
     obj.connectionFormRef.searchModal.onConnect2Target(mysocket.connect2Target);

     var callReconnect = function() {
        RemoteVideoStore.connectRemotes(
          myInfo.myUuid()
        ,myInfo.myEmail()
        ,'reconnect'
        ,mysocket);
     }
     // See any previous connections needs to be reconnected.
     if( mysocket.isSocketOpen() ) {
        callReconnect();
     } else {
        mysocket.onOpen( callReconnect );
     }
     // Check if there is a toUuid 
     //var toUuidElem = document.getElementById('toUuid');

     var arr = window.location.href.split("/");
     var lastone = arr[arr.length-1];
     //console.log("queryString:",window.location.href, " arr:", arr, " lastOne:", lastone);

     //var toUuid = toUuidElem === null ? "" : toUuidElem.value;
     var toUuid = myInfo.myUuid === lastone ? "" : lastone;

     if( toUuid !== "") { // Since toUuid exists, we need to connect
        console.log("Vchat.initSocket.myEmail:", myInfo.myEmail()
          ," myUuid:", myInfo.myUuid(), " toUuid:", toUuid, " isSocketOpen:", mysocket.isSocketOpen());
        
        var checkConn = function() {
          if( mysocket.isSocketOpen() ) {
            mysocket.connect2Target(
              myInfo.myUuid()
              ,myInfo.myEmail()  // It's blank at this point
              ,toUuid
              ,""  // toEmail
              ,"initialUrlWithToUuid"
            );
         } else {
           console.log("Vchat.initSocket. wait 1 second for socket to open...");
           setTimeout(checkConn, 1000);
         }
        }
        checkConn();
     }
   }
   handleSdp(inSdp, inUuid, inEmail, obj){
      //console.log("handleSdp.obj", obj);
      var srchedEmail = obj.connectionFormRef.searchModal.targetEmail(); 
      console.log("Vchat.handleSdp.inEmail:",inEmail,
          " srchedEmail:", srchedEmail);
      // see if it can close the search dialog.
      obj.connectionFormRef.searchModal.checkingCloseDialog(inEmail);

      // connect all the remotes if searchEmail is the same as the inEmail
      if( (srchedEmail === inEmail && inEmail !== '') 
        ||(myInfo.myUuid !== inUuid && inUuid !== '') ) {
        mysocket.connectRemotes(myInfo.myEmail(), inUuid, inEmail,"remote");
      }

     var pp = RemoteVideoStore.updateArgRetPeer(
       this.refs.localVideoRef, mysocket, inUuid, inEmail);
     
     MyPeer.setDescription(pp, mysocket, inSdp, inUuid, inEmail);
   }
   handleIce(inIce, inUuid, inEmail, obj){
     var pp = (inEmail === "") ? RemoteVideoStore.findPeerByUuid(inUuid) 
                               : RemoteVideoStore.findPeer(inEmail);
     console.log("Vchat.handleIce.inIce:", inIce, ' peer:', pp);
     if( pp !== undefined ){
       pp.addIceCandidate(new RTCIceCandidate(inIce) )
         .catch( function(error){
            console.log("*** vchat.handleIce.addIceCandidate error:", error);
         });
     }
   }
  //  syncRemoteArgs(remoteArgs) {
  //    console.log("Vchat.syncRemoteArgs.");
  //   //  this.setState({
  //   //    remoteArgs: remoteArgs
  //   //  });
  //  }
   handleConnectionForm(intype, inText){
     console.log('handleConnectionForm.type', intype, ' inText:', inText);
 
     axios.get('/searchByEmail', {
       params: {email: inText }
     }).then( function (response){
       console.log("searchByEmail.response.data", response.data);
     }).catch( function (error){
       console.log("*** searchByEmail.error:",error);
     });
 
   }
   render(){
     //const { connectionFormRef } = this;
    //  if( myInfo.myEmail() == "" ){
    //   return <div><MySignin ref="connectionFormRef"  origin="vchatPage"/>
    //   <LocalVideo ref="localVideoRef" remoteArgs={this.state.remoteArgs} />
    //   </div>;
    //  } else {
      var video1Arg = this.state.remoteArgs.find( (ii) => ii.videoId === "videoId1" );
      console.log("LocalVideo.video1Arg", video1Arg);

      var mdGrid = {span:8, offset:0}
      var lgGrid = {span:6, offset:0}
      var xlGrid = {span:4, offset:0}

      return <div>
    <TitleBar ref={instance => this.connectionFormRef = instance}
            connection={this.state.connected}
            onStart={this.handleConnectionForm.bind(this, 'start')}
        />
      <Container fluid style={videoStyles.containerStyle}>
       <Row className="justify-content-sm-center" key={1} style={videoStyles.margin0Style}>
         <Col id={video1Arg.videoId + 'col'} sm={12} 
              md={mdGrid} lg={lgGrid} xl={xlGrid}
                key={video1Arg.videoId} style={videoStyles.padding0Style}>
                <VideoPanel inArg={video1Arg} />
                </Col>
          <LocalVideo ref="localVideoRef" remoteArgs={this.state.remoteArgs} />
          {/* <VideoScreenShare/> */}

          {/* { this.state.shareScreen &&
        <Col key={3}  sm={12} 
              md={mdGrid} lg={lgGrid} xl={xlGrid}
              style={videoStyles.padding0Style}>
        <Card bsstyle="success" style={videoStyles.panelStyle}>
        <Badge variant="primary">MyScreenShare</Badge>
        <Card.Body style={videoStyles.padding0Style}> 
        <div className="embed-responsive embed-responsive-4by3">
        <video className="embed-responsive-item" ref="screensharingref" 
           autoPlay muted playsInline controls={true} 
           style={{width:'100%'}} > </video>
        </div>
        </Card.Body>
        </Card>  
        </Col> } */}

        {this.state.remoteArgs.map( 
          function(arg){
              if( arg.videoId === 'videoId1'){
                return ""
              }

              return <Col id={arg.videoId + 'col'} sm={12} 
                md={mdGrid} lg={lgGrid} xl={xlGrid}
                //md={{span:8,offset:2}} lg={{span:6, offset:0}} xl={{span:4, offset:0}}
                key={arg.videoId} style={videoStyles.padding1Hide} >
                <VideoPanel inArg={arg} />
                </Col>
        })}

       </Row>

       <Row key={2}>
        {this.state.remoteArgs.map(
          function(inArg) {
            const arg = {...inArg};
            arg.videoId = arg.videoId + 'share';
            return <Col id={arg.videoId + 'col'} sm={12} 
            key={arg.videoId} style={videoStyles.padding1Hide}>
              <VideoPanel inArg={arg} />
            </Col>
          }
        )}

       </Row>
      </Container>
        
    </div>;
    }
    //}
}

Vchat.propTypes = {
  cookies: instanceOf(Cookies).isRequired
}

export default withCookies(Vchat);

