import React, {Component} from 'react'
import {Form
    , FormGroup
    , FormControl
    , InputGroup
    // , FormLabel
    // , FormText 
  } from 'react-bootstrap'
import {styles} from './styles.js'

import {library} from '@fortawesome/fontawesome-svg-core';
//import { fab }   from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

library.add(faPhone);

export default class SigninPhone extends Component {
    constructor() {
        super();
        this.handleCountryCodeBlur = this.handleCountryCodeBlur.bind(this);
        this.handleCountryCodeChange = this.handleCountryCodeChange.bind(this);
        this.handlePhoneBlur = this.handlePhoneBlur.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.state = {
             countryCode: '+1'
            ,phoneValue: ""
            ,phoneNumber: null
            ,phoneError: null
            ,phoneState: null
        }
    }
    handleCountryCodeChange(e) {
        var countryCode = e.target.value;
        this.setState({countryCode: countryCode});
        console.log("handleCountryCodeChange.e.target.value:", countryCode
            , "this.state.phoneNumber:", this.state.phoneNumber);
        if( countryCode !== "+1" && this.state.phoneNumber != null 
                                && this.state.phoneNumber.length >= 4 ){
            this.setState( {
                 phoneValue: this.state.phoneNumber
                ,phoneError: null
                ,phoneState: "success"});
        } else {
            this.evalPhone(this.state.phoneNumber);
        }
        
    }

    handleCountryCodeBlur(e) {
        var retVal = this.evalPhone(this.state.phoneValue);
        this.processStates(retVal);
    }

    handlePhoneBlur(e) {
        var retVal = this.evalPhone(e.target.value);
        console.log("SigninPhone.handlePhoneBlur.retVal:", retVal);
        this.processStates(retVal);
    }

    processStates(retVal){
        this.setState({
            phoneValue: retVal[0]
            ,phoneError: retVal[1]
            ,phoneState: retVal[2]});
        
        // Retrieve phone string.
        // 1. North America: +1 (344) 345-3243
        // 2. International: +33 343553433
        var phoneStr = this.state.countryCode + " " + retVal[0];
        // Call parent onBlur function.
        this.props.onBlur({fieldName:"phone"
            , fieldValue: phoneStr
            , fieldState: retVal[2]});
    }

    evalPhone(inPhone){
        var phone = inPhone;
        console.log("SigninPhone.handlePhoneBlur.phone:", phone);

        if ( phone === null || phone === ''  ){
            return [this.state.phoneValue
                , "Phone cannot be blank!"
                , "error"];
        }
        var phoneDigits = phone.match(/\d/g); // digits only
        console.log("signinPhone.handlePhoneBlue.phoneDigits:", phoneDigits);
        if( phoneDigits === null ){
            return [
                this.state.phoneValue
                ,"Phone requires digits."
                ,"error"
            ];
        }
        var phoneNumber = phoneDigits.join().replace(/,/g, "");
        this.setState({phoneNumber: phoneNumber});
        console.log("phoneNumber:", phoneNumber);
        if ( phoneNumber.length < 4 ) {
            return [
                  phoneNumber
                , "Phone length has to be longer than 3 digits."
                , "error"];
        }

        if(this.state.countryCode !== "+1"){
            return [
                  phoneNumber
                , null
                , "success"];
        }
        // Now the rules only apply to +1 as North America numbers.
        if( phoneDigits[0] === "1" ) {
            return this.setError("Area code cannot start with 1!");
        } else if ( phoneDigits[0] === "0" ){
            return this.setError("Area code cannot start with 0!");
        } else if ( phoneDigits.length < 10 ){
            return this.setError("Too few digits! 10 digits please.");
            
        } else if ( phoneDigits.length > 10 ) {
            return this.setError("Too many digits! 10 digits please.");
        } else if ( phoneNumber.startsWith("800")
                || phoneNumber.startsWith("844")
                || phoneNumber.startsWith("855")
                || phoneNumber.startsWith("866")
                || phoneNumber.startsWith("877")
                || phoneNumber.startsWith("888") ){
            return this.setError("No toll free number please!");
        } else if ( phoneDigits[3] === "5"
                && phoneDigits[4] === "5"
                && phoneDigits[5] === "5" ){
            return this.setError("No 555 fictional number please!");
        }
        // All good - North America phone validation is a success.
        return [
            this.formatPhone(phoneDigits)
            ,null
            ,"success"];
    }

    // inDigits must be a 10 digits array.
    formatPhone(inDigits) {
        return "(" + inDigits[0] + inDigits[1] + inDigits[2] + ")"
             + " " + inDigits[3] + inDigits[4] + inDigits[5] 
             + "-" + inDigits[6] + inDigits[7] + inDigits[8] + inDigits[9];
    }

    setError(inError){
        return [
             this.state.phoneValue
            ,inError
            ,"error"];
    }

    handlePhoneChange(e){

        var phone = e.target.value;
        //console.log("SigninPhone.handlePhoneChange.phone:", phone);
        this.setState({ phoneValue: phone
            , phoneError: null
            , phoneState: null});
    }

    render() {
        return <FormGroup>
            <Form.Text>Phone Number:</Form.Text>
            <InputGroup className="mb-0">
            <InputGroup.Prepend>
            <InputGroup.Text>
             <FontAwesomeIcon icon="phone" />
             </InputGroup.Text>
          </InputGroup.Prepend>

            <InputGroup.Prepend>
            <Form.Control as="select" id="signinCountryCode"
                onBlur={this.handleCountryCodeBlur}
                onChange={this.handleCountryCodeChange}
                required >
                <option>+1</option>
                <option>+7</option>
                <option>+20</option>
                <option>+27</option>
                <option>+30</option>
                <option>+31</option>
                <option>+32</option>
                <option>+33</option>
                <option>+39</option>
                <option>+41</option>
                <option>+44</option>
                <option>+49</option>
                <option>+61</option>
                <option>+65</option>
                <option>+81</option>
                <option>+82</option>
                <option>+86&nbsp;</option>
            </Form.Control>
            </InputGroup.Prepend>
            <Form.Control id="signinPhoneId"
                type="input"
                value={this.state.phoneValue}
                name={this.props.name}
                onBlur={this.handlePhoneBlur}
                onChange={this.handlePhoneChange}
                autoComplete="phone"
                placeholder="(XXX) XXX-XXXX"
                isInvalid={this.state.phoneState === "error"}
                isValid={this.state.phoneState === "success"}
                required />
            </InputGroup>
            <FormControl.Feedback />
            {this.state.phoneError != null 
                && <Form.Text style={styles.helpBlock}>* {this.state.phoneError}
                   </Form.Text>}
        </FormGroup>
    }
}