import React, {Component} from 'react'
import {Button, Form, FormControl
    ,Card
    ,InputGroup
    ,Nav } from 'react-bootstrap'
import EmailControl from './EmailControl.js'
import ConfirmReset from './ConfirmReset.js'
import {styles} from './styles.js'
import {getUuid} from './Uuid.js'
import {MySessionStorage} from './MySessionStorage.js'
import {Auth} from "aws-amplify";

import {library} from '@fortawesome/fontawesome-svg-core';
//import { fab }   from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';

//import VerificationCodeModal from './VerificationCodeModal';
import MyInfo from '../MyInfo'

library.add(faKey);

//var axios =  require('axios');

var wallStyle = {
    'width': '300px'
    ,'paddingTop': '16px'
    ,'paddingBottom': '6px'
    ,'paddingLeft': '35px'
    ,'paddingRight': '35px'
  };

  const backgroundPath = './images/sunset.jpeg';
  var mainContainerStyle = {
    'paddingLeft': '10px'
   ,'backgroundImage': `url(${backgroundPath})` //"url(" + {backgroundPath} + ")" 
   ,'backgroundSize': 'cover'
   ,'paddingRight': '10px'
   ,'marginTop': '60px'
   ,'height': 'calc(100vh - 45px)'
   ,'width': '100vw'
   ,'display': 'flex'
   ,'justifyContent': 'center'
   ,'alignItems': 'center'
  };

var renderLoginTryed = false;

export default class Login extends Component{
    constructor(props){
        super(props);

        console.log("Login.props:", this.props);

        this._validAuthState = ["signIn", "signedOut", "signedUp"];
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleEmailBlur = this.handleEmailBlur.bind(this);
        this.handlePasswordBlur = this.handlePasswordBlur.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        //this.loginButtonEnabled = this.loginButtonEnabled.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.handleResetSuccess = this.handleResetSuccess.bind(this);
        this.state = {
             email: ''
            ,emailError: null
            ,password: ''
            ,passwordState: null
            ,passwordError: null
        }
    }
    // getPasswordValidationState() {
    //     var passwordVal = this.state.password;
    //     if( this.state.invalidTextVis === 'visible') return 'error'
    //     else if( !this.state.passwordTouched ) return null
    //     else if( passwordVal.length >= 4 ) return null;
    //     else if (passwordVal.length >=2 ) return 'warning';
    //     else return 'error';
    // }

    handleEmailChange(e) {
        //console.log("Login.handleEmailChange.email:", e.target.value);
        this.setState({email: e.target.value});
        //this.setState({invalidTextVis: 'hidden'});
    }
    handleEmailBlur(emailError){
        //console.log("login.handleEmailBlur.emailError:", emailError);
        // It updates state only if it's changed. 
        // It prevents an infinite update loop.
        if( emailError !== this.state.emailError ){
            this.setState({emailError:emailError});
        }
    }

    handlePasswordBlur(e) {
        this.setState({passwordTouched: true});
    }
    handlePasswordChange(e) {
        this.setState({password: e.target.value
            , passwordState: null 
            , passwordError: null});
    }
    
    handleSubmit(e) {
        e.preventDefault();
        return this.doSignIn();
    }

    async doSignIn() {
        // Use only lower case email only.
        const email = this.state.email.toLowerCase();
        const password = this.state.password;
        if( email === '' ){
            this.emailBlur(null);
            return false;
        }

        if( password === ''){
            this.setState({passwordState:'error'
                ,passwordError:'Password cannot be blank!' });
            return false;
        }
        const pp = {logemail: email,logpassword: password};
        console.log("handleSubmit.ss:", pp);

        return this.doAuthSignIn(email, password);
    }

    async doAuthSignIn(email, password){
        try {
            var retUser = await Auth.signIn(email, password);
            console.log("Login.handleSubmit.email:", email, " retUser:", retUser);
            console.log("logged in props:", this.props);

            // let curUser = await Auth.currentAuthenticatedUser();
            // const {attributes } = curUser;
            // let curInfo = await Auth.currentUserInfo();
            // console.log("log.attributes:", attributes, " curInfo:", curInfo);

            // If it gets here, 
            // props.authState is "signedIn"
            // props.authData is a CognitoUser object.
            MyInfo.setMyEmail(email);
            var uuid = MySessionStorage.retrieveOrDefault('myUuid'
            , getUuid());
            console.log("login.response.data.id:", uuid);
            // this.refs.myPageLinkRef.href = "/mypage/" + uuid;
            // this.refs.myPageLinkRef.click();
            this.props.onStateChange("signedIn", {emai: email});
        } catch( err ) {
            if (err.code === "UserNotConfirmedException") {
                //console.log("UserNotConfirmed.props:",this.props, " err:", err);
                //await Auth.resendSignUp(email);
                this.setState({passwordState: 'error'
                    ,passwordError: err.message});
                this.props.onStateChange("confirmSignUp", {email: email, password: password});
            } else if (err.code === "NotAuthorizedException") {
                // incorrect email or password.
                this.setState({passwordState:'error'
                  ,passwordError:'Invalid email or password!'});
            } else if (err.code === "UserNotFoundException"){
                // email doesn't exist.
                this.setState({passwordState:'error'
                  ,passwordError:'Email does not exist!'});
            } else {
                this.setState({passwordState:'error'
                  ,passwordError:'Error: ' + err.message});
                console.error("Login.doSignin.err:", err);
            }
        }

        //console.log("doSignIn.email:", email, " password:", password);
        return;
    }
    handleResetClick(kk) {
        //kk.preventDefault();
        //console.log("Login.handleResetClick.kk:",kk, " email:", this.state.email);
        var thisComponent = this;
        if( this.showResetModal !== undefined ){
            //console.log("Login.handleResetClick.this.state.email:", this.state.email);
            // It sends a password reset request here.
            Auth.forgotPassword(this.state.email)
            .then ( function(data){
                //console.log("Login.handleResetClick.forgotPassword returned. data:", data);
                // The code is sent successfully. Now show the reset modal.
                thisComponent.showResetModal();

            } )
            .catch( function(err){
                //console.log("Login.handleResetClick.forgotPassword.err:", err);
                thisComponent.setState({passwordResetState:'error'
                        ,passwordResetError: err.message});
                console.log("*** Login.handleResetClick.err:", err);
                //}
            } );
        
        }
    }

    handleResetSuccess(isSuccess, newPassword) {
        //console.log("Login.handleResetSuccess.isSuccess:", isSuccess);
        if( isSuccess ) {
            this.setState({password: newPassword});
            // Try to sign in again.
            this.doSignIn();
        }
    }

    render() {
        if( this.props.authState === 'loading' 
            || this.props.authState === 'signIn') {
            console.log( "Login.render().this.props:", this.props);
            var email = "";
            var password = "";
            var authDt = this.props.authData;
            if( authDt != null && authDt !== undefined && authDt.email !== undefined ) {
                email = authDt.email;
                if( authDt.password !== undefined ){
                    password = authDt.password;
                    // Directly do the login if email and password are available.
                    if( email!== "" 
                        && password !== "" 
                        && renderLoginTryed === false) {
                        // We can do this only once to void an infinite loop.
                        renderLoginTryed = true; 
                        this.doAuthSignIn(email, password);
                    }
                }
            }
        }

        return  <div>
        {(this.props.authState==='loading' 
         || this.props.authState === 'signIn')
        && <div style={mainContainerStyle}>
        <Card style={wallStyle}> 
        <Form horizontal='true' onSubmit={this.handleSubmit} method="post">
        <EmailControl controlId="formLoginEmail" 
            name="logemail" 
            value= {email}
            onChange={this.handleEmailChange}
            setBlur={bb => this.emailBlur = bb}
            onBlur={this.handleEmailBlur} />
        <Form.Group controlId="formLoginPassword"
            valid={this.state.passwordState}
            style={styles.formGroup}>
        <Form.Text>Password:</Form.Text>
         <InputGroup className="mb-0">
          <InputGroup.Prepend>
            <InputGroup.Text>
             <FontAwesomeIcon icon="key" />
             </InputGroup.Text>
           </InputGroup.Prepend>
            <FormControl type="password" 
                name="logpassword" 
                // value={password}
                autoComplete="loginpassword" 
                placeholder="Enter your password"
                onBlur={this.handlePasswordBlur}
                onChange={this.handlePasswordChange}
                required/>
          </InputGroup>
            <FormControl.Feedback/>
            {this.state.passwordState != null && <Form.Text style={styles.helpBlock}>* {this.state.passwordError}</Form.Text>}
            {this.state.passwordState === null && <Form.Text style={styles.blankHelpBlock}></Form.Text>}
            {/* <a ref="myPageLinkRef" href="/mypage" hidden/> */}
        </Form.Group>
        <Form.Group> 
                <Nav className="justify-content-between" >
                    <Nav.Item>
                      <Button type="submit" value="LOGIN" 
                      disabled={this.state.passwordError != null
                           || this.state.emailError != null}>Login</Button>
                    </Nav.Item>
                    <Nav.Item> 
                          <Nav.Link eventKey={1} onSelect={kk => this.handleResetClick(kk) }
                          disabled={this.state.emailError != null || this.state.email === ''}>Reset Password</Nav.Link>
                    </Nav.Item>
                </Nav>
                <FormControl.Feedback/>
            {this.state.passwordResetState != null && <Form.Text style={styles.helpBlock}>* {this.state.passwordResetError}</Form.Text>}
            {this.state.passwordResetState === null && <Form.Text style={styles.blankHelpBlock}></Form.Text>}             
        </Form.Group>
        </Form>

        <ConfirmReset setShow={cc => this.showResetModal = cc}  resetSuccess={this.handleResetSuccess}
                      email={this.state.email}></ConfirmReset>
        
    </Card> 
    </div>
    }
    </div>;
    }
}