// import React from 'react';
// import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';

import React from 'react';
import ReactDOM from 'react-dom';
//import Vchat from './Vchat';
import { CookiesProvider } from 'react-cookie';
import MySignin from './login/MySignin.js';
import Login from './login/Login.js';

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
// import { AmplifyAuthenticator
//     , AmplifySignIn
//     , AmplifySignUp } from '@aws-amplify/ui-react';
import { Authenticator } from 'aws-amplify-react';

import VerificationCodeModal from './login/VerificationCodeModal';
import MyMain from './MyMain';

Amplify.configure(aws_exports);

//var myInfo = require('./MyInfo.js');

var containerStyle = {
  'display': 'flex'
  ,'flexWrap': 'wrap'
};

// //function MyMain(props) {
// export default class MyMain extends Component {
//   constructor(props) {
//     super(props);
//   }
//   //var theEmail = props.theEmail;
//   //console.log("MyMain.props:", props);
//   render() { 
//     return (<div>
//     {props.authState === 'signedIn' && <Vchat />
//     }
//     </div>
//   );
// }

const appElement = document.getElementById('root');
// ReactDOM.render( 
// <AmplifyAuthenticator usernameAlias="email"> 
//     <AmplifySignIn headerText="GNETCHAT Sign In" slot="sign-in" />
//     <AmplifySignUp headerText="Sign Up GNETCHAT" slot="sign-up" />
//     <CookiesProvider>
//      <MyMain theEmail={myInfo.myEmail()} />

//     </CookiesProvider>
//   </AmplifyAuthenticator>
//   , appElement
// );

ReactDOM.render( <div style={containerStyle}>
   <CookiesProvider>
      <Authenticator hideDefault={true} amplifyConfig={aws_exports}>
          <MySignin origin="initialLogin" />

          <VerificationCodeModal/>
          <Login/>      
          <MyMain />

      </Authenticator> 
    </CookiesProvider>
    </div>
  , appElement
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
