import React
    , {Component } from 'react';
import {Button
    , Form
    , InputGroup
    , FormControl
    , Modal
  } from 'react-bootstrap';

import {styles} from './styles';
import { Auth } from 'aws-amplify';

import {library} from '@fortawesome/fontawesome-svg-core';
//import { fab }   from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';

library.add(faUserCheck);

export default class VerificationCodeModal extends Component{
   constructor(props){
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleCodeBlur = this.handleCodeBlur.bind(this);
    this.handleResendCode = this.handleResendCode.bind(this);
    this.handleVerify = this.handleVerify.bind(this);

    this.state = {
         show: true 
        ,code: ''
        ,codeError: null
        ,codeState: null
        ,alertClass: null  // null, success or danger
        ,alertMessage: null
    }
   }
   handleClose () {
       this.setState({show: false});
   }
   handleShow() {
       this.setState({show: true});
   }
   handleCodeChange(e){
       this.setState({code: e.target.value
            , codeError: null
            , codeState: null})
       //console.log("verCode.handleCodeChange.state:", this.state);
   }
   handleCodeBlur(e){
       var code = e.target.value;
       if(code === '') {
           this.setState({codeError: "Code cannot be blank!"
            , codeState: "error"});
           return;
       }
       this.setState({codeError: null
            , codeState: "success"
        });
        //console.log("verCode.handleCodeBlur.state:", this.state);
   }
   handleResendCode(e){
       var email = this.props.authData.email;
       console.log("handleResendCode.email:", email);
       Auth.resendSignUp(email)
         .then(data => { 
            console.log("resendSignUp.data:",data);
            this.setState({ 
                 alertClass: "text-success"
                ,alertMessage: "A code sent to " + email + " successfully."});
          })
         .catch(err => {
             console.log("resendSignUp.err:", err);
             this.setState({
                 alertClass: "text-danger"
                 ,alertMessage: "Resend error."
             });
          });
   }
   handleVerify(e) {
       var email = this.props.authData.email;
       var code = this.state.code;
       console.log("handleVerify. code:", code, " email:", email);
       Auth.confirmSignUp(email, code, { forceAliasCreation: true })
        .then( data => {
            console.log("confirmSignUp.data:", data);
            this.setState({
                alertClass: "text-success"
                ,alertMessage: "Your sign up has confirmed successfully.",
                });
            
            // SignUp confirmation code accepted, back to signIn page.
            // Pass on the previous authData
            this.props.onStateChange("signIn", this.props.authData);
          })
        .catch(err => {
            console.log("confirmSignup.err:", err);
            this.setState({
                alertClass: "text-danger"
                ,alertMessage: "* Error: " + err.message
            });
          });
       
   }
   
   render() {
       //console.log("VerificationCodeModal.render() this.props.authData:", this.props.authData);
       return ( <div>
        { this.props.authState === 'confirmSignUp' &&
       <Modal show={this.state.show} onHide={this.handleClose} backdrop="static">
    <Modal.Header closeButton>
        <Modal.Title>Email Verification</Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <Form.Group >
        <Form.Text>An email with verification code has been sent to {this.props.authData.email}.</Form.Text>
      <InputGroup className="mb-0">
       <InputGroup.Prepend>
        <InputGroup.Text>
          <FontAwesomeIcon icon="user-check" />
        </InputGroup.Text>
       </InputGroup.Prepend>

       <FormControl type="text" 
           value={this.state.code}
           name="verificationCode"
           placeholder="Enter verification code"
           onChange={this.handleCodeChange}
           onBlur={this.handleCodeBlur}
           required />

      </InputGroup>
      <FormControl.Feedback />
      {this.state.codeError != null && <Form.Text style={styles.helpBlock}>
   * {this.state.codeError}</Form.Text>}

     </Form.Group>
    </Modal.Body>
      
  <Modal.Footer >
      <Button variant="secondary"
          onClick={this.handleResendCode}
      >Resend Code</Button>
      <Button variant="primary" 
          onClick={this.handleVerify}
          disabled={this.state.codeState === "error" 
                 || this.state.code === ''} >
          Verify
      </Button>
       {this.state.alertMessage != null && <Form.Text className={this.state.alertClass}>{this.state.alertMessage} </Form.Text>}
  </Modal.Footer>
</Modal>
   }
</div> );
   }
}
