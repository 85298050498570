import React, {Component} from 'react'
import {//ImputGroup, 
  Form,
//   FormGroup,
//   FormControl,
//   FormLabel,
  FormText } from 'react-bootstrap'
import {styles} from './styles.js'

export default class SigninAgreeCheckbox extends Component {
    constructor() {
        super();
        this.handleAgreeChange = this.handleAgreeChange.bind(this);
        this.state = {
             agreeError: null
            ,agreeState: null
        }
    }

    // handleAgreeBlur(e){
    //     var isChecked = this.state.agree;
    //     if( isChecked != true ) {
    //         this.setState({agreeError: "Agree checkbox cannot be unchecked."});
    //     } else {
    //         this.setState({agreeError: null});
    //     }

    // }

    handleAgreeChange(e) {
        var isChecked = this.agreeCheckbox.checked;
        console.log("agree:", this.state.agree, " checked:", isChecked, " this.agreeCheckbox", this.agreeCheckbox);
        this.setState({agree: isChecked});

        if( isChecked !== true ) {
            this.setState({agreeState: 'error', agreeError: "Read the Terms of Use and check the box please."});
        } else {
            this.setState({agreeState: null, agreeError: null});
        }

        //console.log("SigninAgreeCheckbox.handleAgreeChange.agree:",this.state.agree);
        this.props.onChange(isChecked);
    }

    render() {
        //this.props.setBlur(this.handleAgreeBlur);
        // inputRef={ref => this.agreeCheckbox = ref}
        return <Form.Check controlId={this.props.controlId}
                          valid={this.state.agreeState}>
              <Form.Check.Input ref = {inst => this.agreeCheckbox = inst} type="checkbox"
                   onChange={this.handleAgreeChange} />
              <Form.Check.Label>I agree to <a href="./termsofuse.html" target="_blank">GNETCHAT Terms of Use</a> &amp;
                <a href="./privacy.html" target="_blank"> Privacy.</a>
              </Form.Check.Label>
            {this.state.agreeError === null && <FormText style={styles.helpBlock}>&nbsp;</FormText>}
            {this.state.agreeError != null && <FormText style={styles.helpBlock}>* {this.state.agreeError}</FormText>}
        </Form.Check>
    }
}
