import React, {Component} from 'react';
import {//Form
      Badge
    , Card} from 'react-bootstrap';
import {videoStyles} from './videoStyles.js';

export default class VideoPanel extends Component{
    // constructor(){
    //     super();
    // }
    
    render(){
        var arg = this.props.inArg;

        return <Card bsstyle="success" style={videoStyles.panelStyle} id={arg.videoId + 'card'}>
        <Badge variant="primary" id={arg.videoId + "email"}>{arg.videoId} {arg.email}</Badge>
        <Card.Body style={videoStyles.padding0Style}> 
        <video id={arg.videoId} key={arg.videoId} 
        autoPlay playsInline controls={true} 
        style={{width:'100%'}}> </video>
        </Card.Body>
        </Card>   
    }
}