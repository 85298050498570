
//var MyPeer =  require('./MyPeer.js');
import MyPeer from './MyPeer.js';

const EventEmitter = require('events');
//var RemoteVideo = require('./RemoteVideo.js');

var myInfo = require('./MyInfo.js');

const myEmitter = new EventEmitter();
const remoteInfoDef = [
    {'toEmail': '','toUuid': ''}
   ,{'toEmail': '','toUuid': ''}
   ,{'toEmail': '','toUuid': ''}
   ,{'toEmail': '','toUuid': ''}
   ,{'toEmail': '','toUuid': ''}
   ,{'toEmail': '','toUuid': ''}
];
var vv = JSON.stringify(remoteInfoDef);
var tt = myInfo.retrieveOrDefault('remoteInfo', vv);
var remoteInfo = JSON.parse( tt);
console.log('RemoteVideoStore.remoteInfo:', remoteInfo);

const remoteArgs = [
     {'toEmail': '','toUuid': '', 'videoId': 'videoId1'}
    ,{'toEmail': '','toUuid': '', 'videoId': 'videoId2'}
    ,{'toEmail': '','toUuid': '', 'videoId': 'videoId3'}
    ,{'toEmail': '','toUuid': '', 'videoId': 'videoId4'}
    ,{'toEmail': '','toUuid': '', 'videoId': 'videoId5'}
    ,{'toEmail': '','toUuid': '', 'videoId': 'videoId6'}
];


//module.exports = {
const RemoteVideoStore = {
    
    handleVideo: function(video) {
        console.log("handleVideo");
    }

    ,getRemoteArgs: function() {
        console.log("RemoteVideoStore.getVideos.remoteArgs:",remoteArgs);
        return remoteArgs.concat();
    }

    ,getRemoteInfo: function() {
        return remoteInfo.concat();
    }

    // ,onNewConn: function(action){
    //     console.log("RemoteVideoStore.onNewConn an action.");
    //     myEmitter.on('newConn', action);
    // }

    ,offNewConn: function(action) {
        console.log("RemoteVideoStore.offNewConn the action.");
        myEmitter.off('newConn', action);
    }
    ,findPeer: function(newEmail) {
        var ss = remoteArgs.find( (arg) => arg.toEmail === newEmail );
        if( ss === undefined ) {
            return undefined;
        } else {
            return ss.peer;
        }
    }
    ,findPeerByUuid(inUuid) {
        var rr = remoteArgs.find( (arg) => arg.toUuid === inUuid);
        return (rr === undefined ) ? undefined : rr.peer;
    }
    ,connectRemotes(inUuid, inEmail, src, mysocket){
        console.log("RemoteVideoStore.connectRemotes.src:", src, 
            " remoteInfo:", remoteInfo);
        if( mysocket !== undefined) {
          mysocket.connectRemotes(myInfo.myEmail(), inUuid, inEmail, src);
        }
    }
    ,emailInRemoteArgs(inEmail){
        var tt = remoteArgs.find( (arg) => arg.toEmail === inEmail );
        if( tt !== undefined) {
            return true;
        }
        return false;
    }
    ,uuidInRemoteArgs(inUuid){
        var tt = remoteArgs.find( (arg) => arg.toUuid === inUuid );
        if( tt !== undefined) {
            return true;
        }
        return false;
    }
    // If it finds the email in remoteArgs, reset it.
    ,resetArgByEmail(newEmail){
        var tt = remoteArgs.find( (arg) => arg.toEmail === newEmail );
        if( tt !== undefined){
            tt.toEmail = "";
            tt.toUuid = "";
            tt.peer = undefined;
        }
    }
    ,resetArgByUuid(newUuid){
        var tt = remoteArgs.find( (arg) => arg.toUuid === newUuid );
        if( tt !== undefined){
            tt.toEmail = "";
            tt.toUuid = "";
            tt.peer = undefined;
        }
    }   
    ,updateArgRetPeer: function(localVideoRef, mysocket, newUuid, newEmail) {
        var ss = remoteArgs.find( (arg) => arg.toUuid === newUuid );
        if( ss === undefined || newUuid === "" ) {
            // find a blank toUuid.
            var bArg = remoteArgs.find((arg) => arg.toUuid === '' );
            if( bArg === undefined){
                console.log("*** updateArgRetPeer no more blank arg left.");
            } else {
                // update remoteInfo first and save it to sessionStorage.
                var ind = remoteArgs.indexOf(bArg);
                console.log("updateArgRetPeer.ind:", ind, ' remoteInfo:',remoteInfo);
                remoteInfo[ind].toEmail = newEmail;
                remoteInfo[ind].toUuid = newUuid;
                sessionStorage.setItem('remoteInfo',JSON.stringify(remoteInfo));

                bArg.toEmail = newEmail;
                bArg.localVideoRef = localVideoRef;
                bArg.mysocket = mysocket;
                bArg.toUuid = newUuid;
                bArg.peer = MyPeer.createPeer(localVideoRef.getStream()
                                , mysocket
                                , newUuid
                                , newEmail
                            );
                // Sync remoteArgs to vChat class.
                //syncFunc( remoteArgs );
                //document.getElementById(bArg.videoId + "card").style.visibility = 'visible';
                document.getElementById(bArg.videoId + "col").style.display = 'initial';
                document.getElementById(bArg.videoId + "email").innerHTML = newEmail;

                // bArg.peer.onaddstream = function(event){
                //     console.log("bArg.peer.onaddstream.videoId:",bArg.videoId);
                //     document.getElementById(bArg.videoId).srcObject = event.stream;
                // }
                
                bArg.peer.ontrack = function(event){
                    console.log("bArg.peer.ontrack.videoId:",bArg.videoId
                       , " track:", event.track
                       , " streams:", event.streams);
                    // Set the video stream
                    if(event.streams && event.streams[0]) {
                       document.getElementById(bArg.videoId).srcObject = event.streams[0];
                    } 
                    // set the shareScreen video stream
                    if(event.streams && event.streams[1]) {
                        // parent element has to be visible first.
                        document.getElementById(bArg.videoId + "sharecol").style.display = 'initial';
                        document.getElementById(bArg.videoId + 'share').srcObject = event.streams[1];
                        document.getElementById(bArg.videoId + 'shareemail').innerHTML 
                            = newEmail + ' screen share';
                    }
                }

                console.log("RemoteVideoStore.updateArgRetPeer.newUuid:", newUuid, 
                " just added a new toUuid. remoteArgs:", remoteArgs);
                return bArg.peer;
            }
        } else {
            console.log("UpdateArgRetPeer toUuid found in remoteArg.toUuid:", newUuid);
            return ss.peer;
        }
    }
};

export default RemoteVideoStore;