//var React = require('react');
import React, {Component} from 'react';

//import myInfo from './MyInfo.js';
import SearchModal from "./SearchModal.js";

import { Navbar
    , Nav
    , Image
    , NavDropdown 
    , Form
} from 'react-bootstrap';

import AvToggle from './AvToggle.js';
import VideoScreenShare from './VideoScreenShare.js';
import {videoStyles} from './videoStyles.js';

import {styles} from "./login/styles.js";

import {Auth} from "aws-amplify"
//import {AmplifySignOut} from '@aws-amplify/ui-react';

import {library} from '@fortawesome/fontawesome-svg-core';
//import { fab }   from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faNetworkWired, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';

library.add(faUser
    , faDesktop
    , faSignOutAlt
    , faNetworkWired);


//var axios = require('axios');

//const Events = require('events');
//const myEvents = new Events();
var myInfo = require('./MyInfo.js');

// var createReactClass = require('create-react-class');
// module.exports = createReactClass({
export default class TitleBar extends Component{
    //getInitialState: () =>({inText: '', retEmails:[], targetEmail:''})
    constructor(){
        //super(props, context);
        super();
        this.handleShareScreen = this.handleShareScreen.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.shareScreenEnd = this.shareScreenEnd.bind(this);

        (async () => {
          let curUser = await Auth.currentAuthenticatedUser();
            var myEmail = curUser.attributes.email;
            myInfo.setMyEmail( myEmail);
            console.log("TitleBar.myPageUrl:", this.getMyPageUrl()
            ,' curUser:', curUser, " myEmail:", myEmail);
            this.setState({myEmail: myEmail});
          })();
        
        this.state = {
             myEmail: ''
             ,shareScreen: false
        };
    }

    getMyPageUrl() {
        return "/mypage/" + myInfo.myUuid();
    }
    shareScreenEnd() {
        this.setState({shareScreen: false});
    }
    handleShareScreen(e) {
        console.log("TitleBar.handleShareScreen() e:", e);
        // Trigger click on localVideo class.

        // Toggle the value.
        var usedVal = this.state.shareScreen ? false: true

        if( usedVal === false && document.getElementById("videoScreenShareId").srcObject ) {
            document.getElementById("videoScreenShareId").srcObject.getTracks()
              .forEach(track => track.stop());
            console.log("titleBar.stoptracks().");
        }
        this.setState({shareScreen: usedVal});
        //document.getElementById("hiddenShareButton").click();

    }
    async signOut() {
        try {
            await Auth.signOut();
        } catch( err) {
            console.log('Error signOut. err:', err);
        }
    }
    handleLogoutClick(e) {
        console.log("logout clicked.");
        // Back to the home link.
        this.signOut();
        this.refs.homeRef.click();
    }
    render() {
        var ellipsisStyle = {
            'width':'100px'
            ,'whiteSpace':'nowrap'
            ,'overflow':'hidden'
            ,'textOverflow':'ellipsis'
            ,'padding':'8px'
        };
    
        var padding8 = {
            'padding':'8px'
            ,'marginLeft':'30px'
        };

        return    <Navbar fixed="top" bg="dark" variant="dark" expand="md">
                    <Navbar.Brand>
                       <Image src="/images/logo.png" responsive="true" style={styles.imageStyle}/>
                    </Navbar.Brand>
    {/* <Form inline>
      <FormControl type="text" placeholder="Search" className="mr-sm-2" />
      <Button variant="outline-info">Search</Button>
    </Form> */}

    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse className="justify-content-between">
        <Form inline>
        <Form.Check 
            type="switch"
            id="videoShareScreeSwitch"
            custom
            variant="primary"
            onClick={this.handleShareScreen}
            style={padding8}>
         {this.state.shareScreen && <div><Form.Check.Input isValid onChange={this.handleShareScreen} checked={this.state.shareScreen} /> 
           <Form.Check.Label > Share:on </Form.Check.Label>
           </div>
         }
         {!this.state.shareScreen && <div><Form.Check.Input onChange={this.handleShareScreen} checked={this.state.shareScreen} /> 
            <Form.Check.Label style={{'color':'white'}}> Share:off </Form.Check.Label>
            </div>
         }
         <Form.Control.Feedback type="invalid">You did it!</Form.Control.Feedback>
        </Form.Check>
        {this.state.shareScreen && <VideoScreenShare shareScreenEnd={this.shareScreenEnd} shareScreen={this.state.shareScreen}/> }
        </Form>

        <SearchModal ref={inst => this.searchModal = inst}/> 
        <NavDropdown.Divider /> 
        
        <Nav className="ml-md-4">
         
        {/* eslint-disable-next-line */}
        <a href="#" className="dropdown-toggle" data-toggle="dropdown" style={ellipsisStyle} ><FontAwesomeIcon icon="user" /> {this.state.myEmail}<b className="caret"></b></a>
        <div title="dropdown" className="dropdown-menu dropdown-menu-right" id="myinfoDropdown">
          <NavDropdown.Item href="#"><FontAwesomeIcon icon="user" /> {this.state.myEmail}</NavDropdown.Item>
          <NavDropdown.Item onClick={this.handleLogoutClick} ><FontAwesomeIcon icon="sign-out-alt" /> Logout</NavDropdown.Item>
        </div>

        </Nav>
        {/* <ul class="dropdown-menu">
            <li><a href="#"><i class="icon-arrow-up"></i> Up</a></li>
            <li><a href="#"><i class="icon-arrow-down"></i> Down</a></li>
        </ul> */}

        {/* <NavDropdown title="{this.state.myEmail}" className="dropdown-menu-right" id="myinfoDropdown">
          <NavDropdown.Item className="dropdown-menu-right" href="#">My Info</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={this.handleLogoutClick} >Logout</NavDropdown.Item>
        </NavDropdown> */}

        {/* <div className="btn-group">
        <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" a*
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        ria-expanded="false">
            <FontAwesomeIcon icon="user" /> <span style={ellipsisStyle}>{this.state.myEmail}</span>
        </button>
        <div title="dropdown" className="dropdown-menu dropdown-menu-right" id="myinfoDropdown">
          <NavDropdown.Item href="#">My Info</NavDropdown.Item>
          <NavDropdown.Item onClick={this.handleLogoutClick} >Logout</NavDropdown.Item>
        </div>
        </div> */}
     
{/* 
      <Form inline>
         <FormControl type="text" placeholder="enter email to connect" className="mx-sm-2" />
         <Button variant="outline-success" >connect</Button>
      </Form> */}

    </Navbar.Collapse>

    <a ref="homeRef" href="/" hidden > </a>
   {/* <Navbar.Collapse>
       <SearchModal ref={inst => this.searchModal = inst}/>
    <Nav pullright="true">
        <Nav.Link eventKey={1} href="#"> {myInfo.myEmail()}</Nav.Link>
        <Nav.Link eventKey={2} href="/logout" >Logout</Nav.Link>
    </Nav>
  </Navbar.Collapse> */}

  </Navbar> ;
    }
}

