import React, {Component} from 'react';
import {Form, InputGroup //, FormControl
    //, FormLabel, FormText 
   } from 'react-bootstrap';
import {styles} from './styles.js';
//import {Auth} from "aws-amplify";
import {library} from '@fortawesome/fontawesome-svg-core';
//import { fab }   from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

library.add(faEnvelope);

//var axios = require('axios');

export default class EmailControl extends Component{
    constructor(){
        super();
        this.handleEmailBlur = this.handleEmailBlur.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        //this.getEmailError = this.getEmailError.bind(this);
        this.state = {
            email: ""
            ,emailState: null
            ,emailError: null
        }
    }
    
    handleEmailBlur(e){
        var emailVal = this.state.email;
        // console.log("EmailControl.handleEmailBlur.emailVal:", emailVal
        //     , " props.value:", this.props.value);
        if(emailVal === ''){
            this.setState({emailState:'error', emailError:'Email cannot be blank!'});
            return;
        }
        const hasAt = emailVal.indexOf('@') !== -1;
        const hasDot = emailVal.indexOf('.') !== -1;
        console.log("SignIn.handleEmailBlur.email", emailVal);
        if( !hasDot) {
            this.setState({emailState:'error', emailError:'The email is missing dot sign!'});
            return;
        }
        if( !hasAt ){
            this.setState({emailState:'error', emailError:'The email is missing @ sign!'});
            return;
        }
        if( emailVal.endsWith('.') ){
            this.setState({emailState:'error', emailError:'Email cannot end with dot!'});
            return;
        }
        if( emailVal.endsWith('@')) {
            this.setState({emailState:'error', emailError:'Email cannot end with @ sign!'});
            return;
        }
        // No need to check the backend if emailState is success.
        if (this.state.emailState === 'success') {
            return;
        }

        return;
        // var myComponent = this;
        // var invalidIfExists = this.props.invalidIfExists;
        // // Check if email exists.
        // Auth.forgotPassword(emailVal)
        //     .then ( function(data){
        //         console.log("EmailContro.checkEmailExist.data:", data);
        //         // Found the email.
        //         var msg = invalidIfExists ? 'Email exists already! Use different one.'
        //                   : 'Email does not exist! Sign up first.';
        //         myComponent.setState({emailState:'error'
        //             , emailError:msg});
        //     } )
        //     .catch( function(err){
        //         console.log("EmailControl.checkEmailExist.err:", err);

        //         //if err.code === 'UserNotFoundException', then the user doesn't exists.
        //         if( err.code === 'UserNotFoundException' ){
        //             //Initial validation succeeded.
        //             myComponent.setState({emailState:'success'});
        //             console.log("EmailControl.checkEmailExist. success - it does not exist.")
        //         }
        //     } );
        // return;
        
        // var pp = {params: {email:emailVal}};
        // axios.get('/emailExists', pp)
        // .then( function(response){
        //     var exists = response.data;
        //     console.log("emailExists.pp:", pp, " response.data:", exists);
        //     if( ( exists === true && !invalidIfExists ) || // xor
        //         ( exists === false && invalidIfExists )
        //          ){
        //         //Initial validation succeeded.
        //         myComponent.setState({emailState:'success'});
        //     } else {
        //         var msg = invalidIfExists ? 'Email exists already! Use different one.'
        //                   : 'Email does not exist! Sign up first.';
        //         myComponent.setState({emailState:'error'
        //             , emailError:msg});
        //     }

        //     return;
        // })
        // .catch( function(err){
        //     console.log("*** emailExists.pp:", pp, " err:", err);
        //     myComponent.setState({emailState:'error'
        //         , emailError:'Email validation error! Check console log.'});
        // });
    }
    handleEmailChange(e){
        this.setState({email: e.target.value, emailError: null, emailState: null});
        // console.log("EmailControl.handleEmailChange.email:", this.state.email
        //     , " props.value:", this.props.value);
        //console.log('EmailControl.handleEmailChange.prop:',this.props);
        // Parent handler
        this.props.onChange(e);
    }

    componentDidUpdate(prePros, preState, preEmailError){
        // console.log("EmailControl.componentDidUpdate.preEmailError:" 
        //    ,preEmailError 
        //  , " this.state:", this.state); //, " snapshot:", snapshot);
        // Send emailError to parent handler function.
        if( this.props.onBlur !== undefined) {
            this.props.onBlur(this.state.emailError);
        }
    }
    render() {
        this.props.setBlur(this.handleEmailBlur);

        return <Form.Group controlId={this.props.controlId}
                          valid={this.state.emailState}
                          style={styles.formGroup}>
          <Form.Text>Email:</Form.Text>
    <InputGroup className="mb-0">
          <InputGroup.Prepend>
            <InputGroup.Text>
             <FontAwesomeIcon icon="envelope" />
             </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control type="email" 
            name={this.props.name} autoComplete="email"
            // value={this.props.value}
            placeholder="Enter your email" required
            onBlur = {this.handleEmailBlur}
            onChange= {this.handleEmailChange} 
            isInvalid = {this.state.emailError != null}
            isValid = {this.state.emailState === 'success' } />
          </InputGroup>
            <Form.Control.Feedback/>
          {this.state.emailError != null && <Form.Text style={styles.helpBlock}>* {this.state.emailError}</Form.Text>}
          {this.state.emailError === null && <Form.Text style={styles.blankHelpBlock}> </Form.Text>}
    </Form.Group>

    }
}