//var React = require('react');

var myInfo = require('./MyInfo.js');
//var myInfo = new MyInfo();

//module.exports = {
const myPeer = {  createPeer: function(localStream, mysocket, newUuid, newEmail) {
    var peerConnectionConfig = {
        'iceServers': [
        {'urls': 'stun:stun.services.mozilla.com'},
        {'urls': 'stun:stun.l.google.com:19302'}
        ]
    };
    // var _myUuid = mysocket.myUuid();
    //var _myEmail = mysocket.myEmail();
    myInfo.logIt('RemoteVideo.createPeer');

    var pp  = new RTCPeerConnection(peerConnectionConfig);
    pp.onicecandidate = function(event) {
        console.log('gotIceCandidate.candidate:',event.candidate);
        if(event.candidate != null) {
            mysocket.send(JSON.stringify({
                'ice': event.candidate,
                'uuid': myInfo.myUuid(),
                'email': myInfo.myEmail(),
                'toUuid': newUuid,
                'toEmail': newEmail
            }));
        }
    }

    // pp.onaddstream = function(event){
    //     videoObj.refs.remotevv.srcObject = event.stream;
    //     console.log('gotRemoteStream remoteVideo.');
    // }
    //pp.addStream(localStream);
    for (const track of localStream.getTracks()) {
        console.log("createPeer before addTrack.track:", track);
        pp.addTrack(track, localStream);
    }
    
    // Add the shared screen track if it's available.
    var sharedObj = document.getElementById("videoScreenShareId");
    if( sharedObj != null && sharedObj !== undefined) {
        let sharedStream = sharedObj.srcObject;
        if(sharedStream != null && sharedStream !== undefined){
          for (const strack of sharedStream.getTracks()) {
            console.log("createPeer before sharedStream.addTrack.strack:",strack);
            pp.addTrack(strack, localStream, sharedStream);
          }
        }
    }

    return pp;
  }
  ,createOffer(inPeer, mysocket, toUuid, toEmail){ 
     if( inPeer === undefined ){
         console.log("MyPeer.createOffer inPeer is undefined. do nothing.");
         return;
     }
     var pp = inPeer;
     pp.createOffer()
     .then(function(description){
         console.log("MyPeer.createOffer.then.");
         pp.setLocalDescription(description)
           .then( function(){
             console.log('setLocalDescription.uuid:',myInfo.myUuid(), 
                         " inEmail:",myInfo.myEmail(),
                         ' toEmail:', toEmail);
             mysocket.send(JSON.stringify({
                 'sdp': pp.localDescription,
                 'uuid': myInfo.myUuid(),
                 'email': myInfo.myEmail(),
                 'toUuid': toUuid,
                 'toEmail': toEmail
             }));
         }).catch(function(err){
             console.log("MyPeer.setLocalDescError.err:",err);
         });
     })
     .catch(function(err){
         console.log("RemoteVideo.createOffer.err:",err);
     });

     return pp;
  }

  ,setDescription: function(inPeer, mysocket, inSdp, inUuid, inEmail){
    console.log("MyPeer.setDescription.inSdp:",inSdp);
    if( inPeer === undefined ){
        console.log("*** MyPeer.setDescription.inPeer is undefined. Cannot do anything.")
        return;
    }
    inPeer.setRemoteDescription(new RTCSessionDescription(inSdp))
          .then(function(){
        console.log("MyPeer.before createAnswer.");
        // Only create ansers to a offer
        if(inSdp.type === 'offer'){
            inPeer.createAnswer().then( function(description) {
                inPeer.setLocalDescription(description)
                   .then( function(){
                     console.log('createAnswer.setLocalDescription.uuid:',myInfo.myUuid(),
                                 " myEmail:",myInfo.myEmail());
                     mysocket.send(JSON.stringify({
                        'sdp': inPeer.localDescription,
                        'uuid': myInfo.myUuid(),
                        'email': myInfo.myEmail(),
                        'toUuid': inUuid,
                        'toEmail': inEmail
                     }));
                   }).catch( function(err){
                    console.log("*** MyPeer.setOffer err:",err);
                   })
            }).catch( function(err){
                console.log("*** MyPeer.createAnswer err:", err);
            });
        } else {
            console.log("MyPeer it is not an offer. inSdp.type:", inSdp.type);
        }
      }).catch(function(err) {
        console.log("*** MyPerr.setRemoteDescription err:", err, " inSdp:", inSdp);
      });
   }
}

export default myPeer;