import React
   , { Component
     } from 'react';

import {Button, Form, FormGroup
    ,Modal
    } from 'react-bootstrap';
import EmailControl from './EmailControl.js';
import SigninAgreeCheckbox from './SigninAgreeCheckbox.js';
import SigninUserName from './SigninUserName.js';
import SigninPhone from './SigninPhone';
import SigninBirthday from './SigninBirthday';
import PasswordControl from './PasswordControl';
import {styles} from './styles.js';

import {library} from '@fortawesome/fontawesome-svg-core';
//import { fab }   from '@fortawesome/free-brands-svg-icons';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { Auth } from 'aws-amplify';

//import VerificationCodeModal from './VerificationCodeModal';

library.add( faKey, faCheckDouble );

export default class SignupModal extends Component{
    constructor(props, context){
        super(props, context);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleAgreeChange = this.handleAgreeChange.bind(this);
        this.handleEmailBlur = this.handleEmailBlur.bind(this);
        this.handleFieldBlur = this.handleFieldBlur.bind(this);
        this.showSignUp = this.showSignUp.bind(this);
        this.handleSignUpSubmit = this.handleSignUpSubmit.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.state = {
            show: false
            ,email: ''
            ,emailError: null
            ,agree: false
            ,validated: false
            ,phoneValue: "+1"  // default value to start.
        }

        console.log("SignModal.constructor.state:", this.state, " props:", props);
    }
    handleModalClose() {
        console.log("SigninItem.handleModalClose()");
        this.setState({show:false});
    }
    handleEmailChange(e) {
        this.setState({email: e.target.value});

        //this.setState({emailState: inState});
        //console.log("SignMondal.handleEmailChange.state:", this.state);
    }
    handleFieldBlur(inObj) {
        console.log("SignModal.handleFieldBlur.inVal:",inObj);
        var fieldName = inObj.fieldName;
        var fieldValue = inObj.fieldValue;
        var fieldState = inObj.fieldState;
        switch ( fieldName ) {
            case "phone": 
                this.setState({phoneValue: fieldValue
                         , phoneState: fieldState});
                break;
            case "userName": 
                this.setState({userNameValue: fieldValue
                         , userNameState: fieldState});
                break;
            case "birthday":
                this.setState({birthdayValue: fieldValue
                        , birthdayState: fieldState});
                break;
            case "password":
                this.setState({passwordValue: fieldValue
                    , passwordState: fieldState});
                // set this password to confirm password component.
                this.setConfirmPw(fieldValue);
                break;
            case "passwordConf":
                this.setState({passwordConfState: fieldState});
                break;
            default:
        } 
    }
    handleAgreeChange(isAgree) {
        console.log("SignModal.handleAgreeChange. isAgree", isAgree);
        this.setState({agree: isAgree});
    }
    handleEmailBlur(emailError) {
        // console.log("signinModal.handleEmailBlur.emailError:", emailError
        //     , ' state:', this.state);
        if( emailError !== this.state.emailError) {
            this.setState({emailError:emailError});
        }
    }
    showSignUp() {
        console.log("SigninItem.showSignup()");
        this.setState({show:true});
    }
    // It returns E.164 phone string.
    // +12324542323 for example.
    getE164(inPhone){
        return inPhone.replace(/\(/g, "")
                .replace(/\)/g, "")  // eslint-disable-line
                .replace(/\-/g, "")  // eslint-disable-line
                .replace(/\ /g, ""); // eslint-disable-line
    }
    handleSignUpSubmit(evt) {
        evt.preventDefault();
        const form = evt.currentTarget;
        // if( form.checkValidity()){
        //     console.log("handleSignUpSubmit. something is invalid.");
        //     return;
        // }
        var phone = this.getE164(this.state.phoneValue);
        var emailLower = form.email.value.toLowerCase();
        var epoch = new Date().getTime().toString();
        //var updated_at = date.toISOString();
        console.log("handleSignUpSubmit.form:", form
           , 'email:', emailLower
           , ' username:', form.username.value
           , ' phone:', phone
           , ' birthday:', this.state.birthdayValue
           , ' password:', this.state.passwordValue
           , ' update_at:', epoch);
        Auth.signUp({
            username: emailLower // username
            ,password: this.state.passwordValue
            ,attributes: {
                email: emailLower
                ,name: form.username.value
                ,phone_number: phone
                ,birthdate: this.state.birthdayValue
                ,updated_at: epoch
            }})
            .then( data => {
                console.log("signUp.data:",data);
                this.setState({signUpError: null, show:false});
                if( data.userConfirmed === false ){
                    // Call parent function about the signModalSuccess event.
                    this.props.authStateChange(emailLower, this.state.passwordValue);
                }
            })
            .catch( err => {
                console.log("signup.err:", err);
                this.setState({signUpError: err.message })
            });
        // if( form.checkValidity() === false ){
        //     evt.preventDefault();
        //     evt.stopPropagation();
        // }
        //this.setState({validated: true});
    }

    isFormValid() {
        return this.state.emailError !== null 
        || this.state.email === '' 
        || this.state.userNameState !== "success"
        || this.state.phoneState !== "success"
        || this.state.birthdayState !== "success"
        || this.state.passwordState !== "success"
        || this.state.passwordConfState !== "success"
        || this.state.agree === false ;
        // return this.state.userNameState != "success"
        //           || this.state.phoneState != "success"
        //           || this.state.birthdayState != "success"
        //           || this.state.passwordState != "success"
        //           || this.state.passwordConfState != "success";     
    }

    render() {
        return   <Modal  
            show={this.state.show} 
            onHide={this.handleModalClose}
            backdrop="static">
<Modal.Header closeButton>
    <Modal.Title>Create an account</Modal.Title>
</Modal.Header>
<Modal.Body style={styles.modalBodyStyle}>
    <Form onSubmit={this.handleSignUpSubmit} >
        <EmailControl controlId="formRegisterEmail" 
            name="email"
            invalidIfExists={true}
            onChange={this.handleEmailChange}    
            setBlur = {bb => this.emailBlur = bb}
            onBlur={this.handleEmailBlur} 
            />

        <SigninUserName controlId="formRegisterUserName" 
            name="username"
            onBlur={this.handleFieldBlur}/>

        <SigninPhone controlId="formRegisterPhone"
            name="phone"
            onBlur = {this.handleFieldBlur} />

        <SigninBirthday controlId="formRegisterBirthday"
            name="bithday"
            phoneValue={this.state.phoneValue}
            onBlur={this.handleFieldBlur} />

        <PasswordControl controlId="formRegisterPassword"
           fieldDescription="Password - number(s), upper, lower cases, min 8 chars:"
           iconName="key"
           name="password"
           autoComplete="new-password"
           placeholder="Enter a password"
           onBlur={this.handleFieldBlur}
        />

        <PasswordControl controlId="formRegisterConfirmPassword"
            fieldDescription="Confirm Password:"
            iconName="check-double"
            name="passwordConf"
            autoComplete="new-password"
            placeholder="Enter the same password again"
            onBlur={this.handleFieldBlur}
            onConfirmToPassword={ ss => this.setConfirmPw = ss }
        />

      <Modal.Footer style={styles.modalFooterStyle}/>      
      <SigninAgreeCheckbox controlId="formRegisterLicense"
                        onChange={this.handleAgreeChange} />
      <FormGroup>
            <Button type="submit" 
               value="REGISTER" 
               disabled={ this.isFormValid() === true } >Sign Up</Button>
            {this.state.signUpError != null 
                && <Form.Text style={styles.helpBlock}>* {this.state.signUpError}
                   </Form.Text>}
      </FormGroup>
    </Form>
</Modal.Body>
</Modal>;
    }
}