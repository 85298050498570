import React
    , {Component} from 'react';

import Vchat from './Vchat';
//import {Auth} from 'aws-amplify';

//function MyMain(props) {
export default class MyMain extends Component {
    constructor(props) {
        super(props);
        console.log("MyMain.props:", props);
    }
    //var theEmail = props.theEmail;
    //console.log("MyMain.props:", props);
    render() { 
        //console.log("MyMain.render() props.authState:", this.props.authState);
        return (<div>
        {this.props.authState === 'signedIn' && <Vchat />}
        </div>
        );
    }
}