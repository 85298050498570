import React, {Component} from 'react'
import {Container
    , Row
    , Col
    , Card
    , Badge
    , Button
  } from 'react-bootstrap'

import VideoPanel from './VideoPanel.js'

import AvToggle from './AvToggle.js';
//import {styles} from "./login/styles.js";
import {videoStyles} from './videoStyles.js';

const EventEmitter = require('events');
class MyEmitter extends EventEmitter {};
const localEmitter = new MyEmitter();

// var createReactClass = require('create-react-class');
// module.exports = createReactClass({
export default class LocalVideo extends Component {
    constructor() {
        super();
        this.getUserMediaSuccess = this.getUserMediaSuccess.bind(this);
        //this.handleShareClick = this.handleShareClick.bind(this);
        this.getStream = this.getStream.bind(this);

        // this.state = {
        //   shareScreen: false
        // };
    }
    getUserMediaSuccess(stream) {
        console.log('localVideo.getUserMediaSuccess');
        this.refs.videoref.srcObject = stream;
        localEmitter.emit("mediaSuccess");
    }
    getStream() {
        return this.refs.videoref.srcObject;
    }
    errorHandler(err) {
        console.log('LocalVideo error:',err);
    }

    onMediaSuccess(callback) {localEmitter.on("mediaSuccess", callback);}
    offMediaSuccess(callback) {localEmitter.off("mediaSuccess", callback);}

    // handleShareClick(e) {
    //   console.log("LocalVideo.handleShareClick().e:", e);
    //   this.setState({shareScreen: true});
    // }

    render() {
        var constraints = {
            video: true,
            audio: true
        };
        if(navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia(constraints)
                     .then(this.getUserMediaSuccess).catch(this.errorHandler);
        }
        else {
            alert('Your browser does not support getUserMedia API');
        }
        
        // try {
        //   if( navigator.mediaDevices.getDisplayMedia) {}
        //     navigator.mediaDevices.getDisplayMedia({video:true})
        //         .then(this.getUserMediaSuccess).catch(this.errorHandler);
        //   //ideoElement.srcObject = mediaStream;
        // } catch (e) {
        //   console.log('Unable to acquire screen capture: ' + e);
        // }

        //console.log("LocalVideo.videoCount:", videoCount, " video1Arg", video1Arg);

        var mdGrid = {span:8, offset:0}
        var lgGrid = {span:6, offset:0}
        var xlGrid = {span:4, offset:0}

      //   return  <Container fluid style={videoStyles.containerStyle}>
      //  <Row className="justify-content-sm-center" key={1} style={videoStyles.margin0Style}
      //    >
      //    <Col id={video1Arg.videoId + 'col'} sm={12} 
      //         md={mdGrid} lg={lgGrid} xl={xlGrid}
      //           key={video1Arg.videoId} style={videoStyles.padding0Style}>
      //           <VideoPanel inArg={video1Arg} />
      //           </Col>
       {/* </Row> */}
       {/* <Row className="show-grid" key={2} style={margin0Style}
             sm={12} md={10} lg={8} xl={8}
          > */}
        return <Col key={2}  sm={12} 
              md={mdGrid} lg={lgGrid} xl={xlGrid}
            //  md={{span:8,offset:2}} lg={{span:6, offset:0}} xl={{ span:4, offset:0 }}
              style={videoStyles.padding0Style}>
        <Card bsstyle="success" style={videoStyles.panelStyle}>
        <Badge variant="primary">Me </Badge>
        <Card.Body style={videoStyles.padding0Style}> 
        <div className="embed-responsive embed-responsive-4by3">
        <video className="embed-responsive-item" ref="videoref" 
           autoPlay muted playsInline controls={true} 
           style={{width:'100%'}} > </video>

        </div>

        {/* <Card.ImgOverlay style={{'paddingTop': '30px'}}>

        <AvToggle name="video" 
            iconSlash="video-slash" 
            iconName="video" 
            slashPadding={videoStyles.slashPadding}
            successPadding={videoStyles.successVideo}
            />
        <Card.Link > </Card.Link>
        <AvToggle name="audio" 
            iconSlash="microphone-slash" 
            iconName="microphone" 
            slashPadding={videoStyles.slashPadding}
            successPadding={videoStyles.successAudio}
            />

        </Card.ImgOverlay> */}
        </Card.Body>
        </Card>  
        </Col>;

        // { this.state.shareScreen &&
        // <Col key={3}  sm={12} 
        //       md={mdGrid} lg={lgGrid} xl={xlGrid}
        //       style={videoStyles.padding0Style}>
        // <Card bsstyle="success" style={videoStyles.panelStyle}>
        // <Badge variant="primary">MyScreenShare</Badge>
        // <Card.Body style={videoStyles.padding0Style}> 
        // <div className="embed-responsive embed-responsive-4by3">
        // <video className="embed-responsive-item" ref="screensharingref" 
        //    autoPlay muted playsInline controls={true} 
        //    style={{width:'100%'}} > </video>
        // </div>
        // </Card.Body>
        // </Card>  
        // </Col> }

        // {this.props.remoteArgs.map( 
        //   function(arg){
        //       if( arg.videoId === 'videoId1'){
        //         return ""
        //       }

        //       return <Col id={arg.videoId + 'col'} sm={12} 
        //         md={mdGrid} lg={lgGrid} xl={xlGrid}
        //         //md={{span:8,offset:2}} lg={{span:6, offset:0}} xl={{span:4, offset:0}}
        //         key={arg.videoId} style={videoStyles.padding1Hide} >
        //         <VideoPanel inArg={arg} />
        //         </Col>
        // })}
        // </Row>
        // </Container>;
     }
}