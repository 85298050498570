var Uuid = require('./login/Uuid.js');
var Ms = require('./login/MySessionStorage.js');


//var _myUuid = retrieveOrDefault('myUuid', document.getElementById('userUuid').value);
var _myEmail = null;

module.exports = {
    retrieveOrDefault: function(strName, defVal) {
        return Ms.MySessionStorage.retrieveOrDefault(strName, defVal);
    }
    ,myUuid: function() {
        var myElem = document.getElementById('userUuid');
        var usedVal = (myElem === null) ? Uuid.getUuid() : myElem.value;
        //usedVal = (usedVal ==)
        var theUuid = Ms.MySessionStorage.retrieveOrDefault('myUuid', usedVal);
        //console.log("MyInfo.myUuid:", theUuid);
        return theUuid;
    }
    ,setMyEmail: function(inEmail){
        _myEmail = inEmail
    }
    ,myEmail: function() {
        console.log("MyInfo.myEmail()._myEmail:", _myEmail);
        if( _myEmail === null ){
            _myEmail = sessionStorage.getItem('myEmail');
            var userEmailElement = document.getElementById('userEmail');
            if( userEmailElement === null ){
                return ""; // The element doesn't exist
            }
            var userEmail = userEmailElement.value;

            //console.log("_myEmail:", _myEmail, " userEmail:", userEmail);
            // If the email is different from the last one, log out.
            if( _myEmail != null && _myEmail !== userEmail ) {
                var ee = document.getElementById('userLogin');
                if( ee != null){
                    // reset sessionstorage data and relogin
                    sessionStorage.setItem('myEmail', null);
                    ee.click(); // login page without kill the session.
                }
            }
            _myEmail = userEmail;
            sessionStorage.setItem('myEmail', _myEmail);
        }
        return _myEmail;
    }
    ,logIt: function(msg){
        console.log(msg, ' myinfo.myUuid:', this.myUuid(), ' myEmail:', this.myEmail());
    }
}
