import React, {Component} from 'react'

import {
    //  Modal
    // , FormText
      Navbar, Nav //, NavItem
    , Image } from 'react-bootstrap'
//import EmailControl from './EmailControl.js'
import {styles} from './styles.js'
//import Login from "./Login.js"
import SigninModal from './SignModal.js'

//var myInfo = require('../MyInfo.js');

//var axios = require('axios');

// var modalBodyStyle = {
//     'marginLeft':'30px'
//     ,'marginRight':'30px'
// }

export default class MySignin extends Component{
    constructor(props){
        super(props);
        this.showLogin = this.showLogin.bind(this);
        //this.showSignUp = this.showSignUp.bind(this);
        this.handleLoginModalClose = this.handleLoginModalClose.bind(this);
        this.handleAuthStateChange = this.handleAuthStateChange.bind(this);
        this.state = {            
            showLogin: false
        }
        console.log("MySignin.props:", props);
    }
    // place holders. The same from TitleBar class.
    // Therefore, unregistered user would have signin title bar instead.
    onConnect2Target(callback) {}
    offConnect2Target(callback) {}
    targetEmail() {    return ""; }
    checkingCloseDialog() {}

    // handleModalClose() {
    //     this.setState({show:false});
    // }
    handleLoginModalClose() {
        this.setState({showLogin:false});
    }
    // showSignUp() {
    //     console.log("showSignUp");
    //     this.setState({showSignUp:true});
    //     this.refs.mySigninItem.showSignUp();
    // }
    showLogin() {
        console.log("Signin.showLogin");
        this.setState({showLogin:true});
    }
    // handleKeyPress(e) {
    //     if(e.key === 'Enter')  {
    //         console.log("enter key is pressed.");
    //     }
    // }
    // handleEmailChange(e){
    //     this.setState({email: e.target.value});
    // }
    handleAuthStateChange(email, password) {
        console.log("handleAuthStateChange.email:", email, " this.props:", this.props);
        //this.props.authData.email = email;
        this.props.onStateChange("confirmSignUp", {'email': email, "password": password });
    }
    render() {  
        var origin = this.props.origin;
        console.log("MySignin.props.origin:", origin);

        return   <Navbar fixed="top" bg="dark" variant="dark">
        <Navbar.Collapse  className="justify-content-between" >
        <Navbar.Brand>
           <Image src="/images/logo.png" responsive="true" style={styles.imageStyle}/>
        </Navbar.Brand>

        <Nav>
        <Nav.Item>
            <Nav.Link href="#" onClick={this.showLogin} style={origin==="initialLogin" ? styles.hideIt : {} } >Login In
            </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={1} href="#" onClick={() => this.refs.mySigninModal.showSignUp()}>Sign Up</Nav.Link>
        </Nav.Item>
        </Nav>
        </Navbar.Collapse>
        {/* <Modal show={this.state.showLogin} onHide={this.handleLoginModalClose} >
            <Modal.Header closeButton>  </Modal.Header>
            <Modal.Body style={styles.modalBodyStyle}>
            <Login/>
            </Modal.Body>
        </Modal> */}
        <SigninModal authStateChange={this.handleAuthStateChange} ref="mySigninModal"/>
</Navbar>;
    }
}