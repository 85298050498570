import RemoteVideoStore from './RemoteVideoStore.js';

//const WebSocket = require('ws');
const EventEmitter = require('events');
class MyEmitter extends EventEmitter {};
const myEmitter = new MyEmitter();

var myInfo = require('./MyInfo.js');
//var myInfo = new MyInfo();
//var _myuuid = "";

//var RemoteVideoStore = require('./RemoteVideoStore.js');

//module.exports = function() {
//export default function mySocket() {
    
    //var ws = new WebSocket('wss://' + host + ':9082');
    //var ws = new WebSocket('wss://nzojbkzzu0.execute-api.us-west-2.amazonaws.com/Prod');
// const ws = new WebSocket('ws://' + window.location.hostname + ':9082');
// const wss = new WebSocket('wss://localhost:9443');
var wss = null;

var _socketIsOpen = false;



// var closeWss = function () {
//     wss.close();
// }

var constructAndSend = function (fromUuid, fromEmail, toUuid, toEmail, src){
    var ss = {'uuid': fromUuid
        ,'newConn': 'true'
        ,'email': fromEmail
        ,'toUuid': toUuid
        ,'toEmail': toEmail
        ,'src': src
    };
    // The message format below.
    // {"message":"sendmessage", "data":"incomdingTest"}
    var goingMsg = {"action":"sendMessage"
              , "toInfo": {'toUuid': toUuid,'toEmail': toEmail}
              , "data":JSON.stringify(ss)};
    console.log("mySocket.constructAndSend.goingMsg:", goingMsg);

    wss.send(JSON.stringify(goingMsg));    
}

var connectRemotes = function(myEmail, inUuid, inEmail,src){
    var remoteInfo = RemoteVideoStore.getRemoteInfo();
    console.log("MySocket.connetRemotes.myEmail:", myEmail
        ," inUuid:", inUuid
        ," inEmail:", inEmail
        , " remotes:", remoteInfo);

    remoteInfo.forEach(function(item) {
        if( ( item.toEmail !== "" && 
              item.toEmail !== inEmail && 
              item.toEmail !== myEmail) 
             || ( item.toUuid !== "" && 
                  item.toUuid !== inUuid &&
                  item.toUuid !== myInfo.myUuid()) ){
                console.log("connectRemotes do reconnect inEmail:",
                    inEmail, " inUuid:", inUuid, " with this.item:", item);
                constructAndSend( 
                      inUuid
                    , inEmail
                    , item.toUuid
                    , item.toEmail
                    , src);
        }
    })
}

var confirmConn = function(rr) {
    let prom = new Promise(function(resolve, reject){
        var acceptRes = true;
        if( acceptRes === true){
          resolve ();
        } else {
          reject (Error("Rejected"));
        }
       });
       prom.then( function(){
         var  acceptRes = window.confirm("Would you like to accept a connection from " + rr.email);
           console.log('vchat.handleNewConn.rr', rr,
            " accepRes:",acceptRes);
  
         //var acceptRes = true;
         if( acceptRes === true ){
            myEmitter.emit('newConn', rr);
            // if my remoteInfo is not empty setup connection between them too.
            //RemoteVideoStore.connectRemotes(rr.uuid, rr.email, 'remote', this);
            connectRemotes(myInfo.myEmail(), rr.uuid, rr.email,"remote");
         }
       }, function(err){
         console.log(err);
       })
       console.log("MySocket.confirmConn ended.");
}


const mySocket = {
    constructSocket: function() {
        wss = new WebSocket('wss://jdmqm1ixad.execute-api.us-west-2.amazonaws.com/linglin_development?QueryAuth1=queryValue1');
        console.log("MySocket.constructSocket.wss created.");

        wss.onclose = function() {
            console.log('MySocket closed')
        }
        wss.onerror = function(err){ 
            console.log('MySocket error.', err)
        }
        wss.onopen = function() {
            _socketIsOpen = true;
        
            myInfo.logIt("MySocket.open." );
            myEmitter.emit('open');
            // Send myInfo when the socket is open.
            var myInfoMsg = {"action":"uploadMyInfo"
                ,"myInfo":{"myUuid":myInfo.myUuid()
                          ,"myEmail":myInfo.myEmail() }};
            
            var retMsg = wss.send(JSON.stringify(myInfoMsg));
            console.log("onOpen.myInfoMsg:", myInfoMsg, " retMsg:", retMsg);
        }
        wss.onconnect = function() { 
            console.log('MySocket.connected.');
        }

        wss.onmessage = function(msg) {
            console.log("MySocket.onmessage_1.msg:", msg);
        
            var dd = JSON.parse(msg.data);
            console.log('MySocket.gotmessage_3.data:', dd
                , " myUuid:", myInfo.myUuid());
            if(dd.uuid === myInfo.myUuid()){
                console.log("wss.onmessage. Same uuid from the server. Do nothing");
                return;
            } 
            else if ( dd.email === myInfo.myEmail() && myInfo.myEmail() !== "") {
                console.log("wss.onmessage. Same email from the server.email:", dd.email, " Do nothing.");
            }
            else if ( dd.sdp != null ) {
                console.log("wss.onmessage.sdp received. toEmail:", dd.toEmail);
                // if( dd.toEmail == myInfo.myEmail() && dd.toUuid == myInfo.myUuid() ) {
                if( dd.toUuid === myInfo.myUuid() ) {
                    myEmitter.emit('sdp', dd.sdp, dd.uuid, dd.email );
                } else {
                    console.log('wss.onmessage.sdp toEmail or toUuid do not match. Do nothing. toEmail:', dd.toEmail,
                                ' toUuid:', dd.toUuid,
                                ' myEmail:', myInfo.myEmail());
                }
            } else if( dd.ice != null ) {
                console.log("wss.onmessage.ice received");
                //if( dd.toEmail == myInfo.myEmail() && dd.toUuid == myInfo.myUuid() ){
                if( dd.toUuid === myInfo.myUuid() ){
                    myEmitter.emit('ice', dd.ice, dd.uuid, dd.email);
                } else {
                    console.log('wss.onmessage.ice toUuid no match. toEmail:', dd.toEmail
                                ,' toUuid:', dd.toUuid
                                ,' myUuid:', myInfo.myUuid()
                                ,' myEmail:', myInfo.myEmail());
                }
            } 
            else if (dd.newConn) {
                // - It only popups the confirm box if toUuid is blank and toEmail matches myEmail.
               if( dd.toEmail === myInfo.myEmail() && (dd.toUuid === '' || dd.toUuid === undefined || dd.toUuid == null) ) {
                   confirmConn(dd);
                } else if (dd.src === 'remote' && dd.uuid !== "" && RemoteVideoStore.uuidInRemoteArgs(dd.uuid)){
                   //
                   console.log("wss.onMessage. dd.src is remote and dd.uuid is arelady in remoteArgs. dd:", dd);
                } else if (dd.src === 'remote' && dd.email !== "" && RemoteVideoStore.emailInRemoteArgs(dd.email)){
                   // There is no need to proceed if src == remote and inEmail is already in remoteInfo list.
                   console.log("wss.onMessage. dd.src is remote and dd.email already in remoteArgs. dd:",dd);
               } else if( dd.toUuid === myInfo.myUuid() ){        
                   // - There is no need to popup the confirm box if toUuid is myUuid.
                   // No confirmation needed. Immediately connect.
                   console.log("Mysocket emit.newConn.dd:", dd);
                   myEmitter.emit('newConn', dd);
               } else {
                    // - It does nothing if toUuid is not blank and not equal to myUuid.
                    console.log("wss.onmessage. Different toUuid just received. Do nothing. myUuid:", 
                        myInfo.myUuid(), "myEmail:", myInfo.myEmail(), " dd:", dd);
               }
                //myEmitter.emit('newConn', dd);
            } else {
                console.log("Unknown msg uuid:", dd.uuid, " email:", dd.email);
            }
        }
        
    },    
    onNewConn: function(callback) {
        console.log("MySocket.onNewConn.");
        myEmitter.on('newConn', callback);
    },
    offNewConn: function(callback) {
        console.log("mySocket.offNewConn.");
        myEmitter.off('newConn', callback);
    }

    ,onSdp:  function(cb) {myEmitter.on('sdp', cb); }
    ,offSdp: function(cb) {myEmitter.off('sdp', cb); }

    ,onIce:  function(cb) {myEmitter.on('ice', cb); }
    ,offIce: function(cb) {myEmitter.off('ice', cb); }

    ,onOpen: function(cb) {myEmitter.on('open', cb);}
    ,offOpen: function(cb) {myEmitter.off('open', cb);}

    , connect2Target: function (fromUuid, fromEmail, toUuid, toEmail, src){
        constructAndSend(fromUuid, fromEmail, toUuid, toEmail, src);  
    }
    ,connectRemotes: function (myEmail, inUuid, inEmail,src) {
        connectRemotes(myEmail, inUuid, inEmail, src);
    }
    ,send: function(pkg) {
        console.log('MySocket.send.pkg:', pkg);
        var goingMsg = {"action":"sendMessage"
             , "toInfo": {"toUuid": JSON.parse(pkg).toUuid, "toEmail": JSON.parse(pkg).toEmail}
             , "data":pkg};
        wss.send(JSON.stringify(goingMsg));  
    }
    ,isSocketOpen: function() {
        return _socketIsOpen;
    }
    ,close: function() {
        if( wss != null ) {
          wss.close();
          wss = null;
        }
    }
};

export default mySocket;
//};