export const MySessionStorage = {
    retrieveOrDefault: (strName, defVal) => {
        var retVal = sessionStorage.getItem(strName);
        if( retVal === undefined || retVal == null || retVal === '') {
            // save it to the session;
            sessionStorage.setItem(strName, defVal);
            return defVal;
        }
        //console.log('retriveOrDefault.sessionStorage:',sessionStorage);
        return retVal;
    }
}