import React, {Component} from 'react'
import {Form
    , FormGroup
    , FormControl
    , InputGroup
    // , FormLabel
    // , FormText 
  } from 'react-bootstrap'
import {styles} from './styles.js'

import {library} from '@fortawesome/fontawesome-svg-core';
//import { fab }   from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBirthdayCake, faAnchor } from '@fortawesome/free-solid-svg-icons';

library.add(faBirthdayCake, faAnchor);

export default class SigninBirthday extends Component {
    constructor() {
        super();
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleMonthChange = this.handleMonthChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);

        this.handleDayBlur = this.handleDayBlur.bind(this);
        this.handleMonthBlur = this.handleMonthBlur.bind(this);
        this.handleYearBlur = this.handleYearBlur.bind(this);
        this.state = {
             dayValue: ""
            ,monthValue: ""
            ,yearValue: ""
            ,birthdayValue: ""
            ,birthdayError: null
            ,birthdayState: null
        }
    }
    handleDayChange(e) {
        var day = e.target.value;
        this.setState({dayValue: day});
        this.clear3States();
        //console.log("handDayChange.e.target.value:", day)
    }
    handleMonthChange(e) {
        this.setState({monthValue: e.target.value});
        this.clear3States();
    }
    handleYearChange(e) {
        this.setState({yearValue: e.target.value});
        this.clear3States();
    }

    clear3States() {
        this.setState({dayState: null
            , monthState: null
            , yearState: null});
    }
    handleDayBlur(e) {
        var day = e.target.value;
        //console.log("handleDayBlur.day:", day);
        var ret = this.evalBirthday(day, this.state.monthValue
            , this.state.yearValue);
        //console.log("handleDayBlur.ret:", ret);
        this.uplinkBirthday(ret);
    }
    handleMonthBlur(e){
        var month = e.target.value;
        var ret = this.evalBirthday(this.state.dayValue, month
            , this.state.yearValue);
        //console.log("handleMonthBlur.ret:", ret);
        this.uplinkBirthday(ret);
    }
    handleYearBlur(e) {
        var year = e.target.value;
        var ret = this.evalBirthday(this.state.dayValue
            , this.state.monthValue, year);
        //console.log("handleYearBlur.ret:", ret);
        this.uplinkBirthday(ret);
    }

    uplinkBirthday(inArr) {
        this.setState({
            birthdayValue: inArr[0]
            ,birthdayError: inArr[1]
            ,birthdayState: inArr[2]
        });
        this.props.onBlur({
            fieldName: "birthday"
            ,fieldValue: inArr[0]
            ,filedError: inArr[1]
            ,fieldState: inArr[2]
        });
    }

    // inStr cannot be null
    getDigitStr(inStr){
        // Only keeps the digits.
        var digitArr = inStr.match(/\d/g);
        if (digitArr === null){
            return ""; // No digit found.
        }
        // Combine the digits to a string.
        var retStr = digitArr.join().replace(/,/g, "");
        return retStr;
    }

    // Append "0" to from for single digits.
    append0IfSingle(inDigits){
        return inDigits.length === 1 
            ? "0" + inDigits
            : inDigits;
    }

    retError( inType, birthday, errorStr ){
        switch( inType ) {
            case "day":   this.setState({dayState: "error"}); break;
            case "month": this.setState({monthState: "error"}); break;
            case "year":  this.setState({yearState: "error"}); break;
            default: console.error("retError unknown inType:",inType);
        }
        return [ birthday
            , errorStr
            , "error"];
    }

    isLeapYear(year) {
        if(year === "") {
            return false;
        }
        return ((year % 4 === 0) && (year % 100 !== 0)) || year % 400 === 0 
    }
    getMaxDay(month, year){
        switch(month) {
            case "01": 
            case "03": 
            case "05": 
            case "07": 
            case "08": 
            case "10":
            case "12": return 31;
            case "04":
            case "06": 
            case "09":
            case "11": return 30;
            case "02": return this.isLeapYear(year) ? 29 : 28;
            default: return 31;
        }
    }
    evalBirthday(inDay, inMonth, inYear) {
        var day = this.append0IfSingle(this.getDigitStr(inDay));
        var month = this.append0IfSingle(this.getDigitStr(inMonth));
        var year = this.getDigitStr(inYear);
        this.setState({ dayValue: day
            , monthValue: month
            , yearValue: year});

        var maxDay = this.getMaxDay(month, year);
        console.log("maxday:", maxDay, " month:", month, " year:", year);
        // IOS date like: 1980-04-04.
        var birthday = year + "-" + month + "-" + day;
        if( inDay === "" ){
            return this.retError("day", birthday, "Day cannot be blank!");
        } else if (day.length < 1 ){
            return this.retError("day", birthday, "Too few day digit!");
        } else if ( day < 1 ) {
            return this.retError("day", birthday, "Day cannot be 0!");
        } else if ( day > maxDay ) {
            return this.retError("day", birthday, "Day cannot be greater than " 
                + maxDay + "!");
        }

        if( inMonth === ""){
            return this.retError("month", birthday, "Month cannot be blank!");
        } else if( month.length < 1 ) {
            return this.retError("month", birthday, "Too few month digit!");
        } else if( month < 1 ) {
            return this.retError("month", birthday, "Month cannot be 0!");
        } else if( month > 12 ) {
            return this.retError("month", birthday
            , "Month cannot be greater than 12!");
        }

        if( inYear === "" ) {
            return this.retError("year", birthday, "Year cannot be blank!");
        } else if( year.length < 4 ){
            return this.retError("year", birthday, "Too few year digits!");
        } else if( year.length > 4 ){
            return this.retError("year", birthday, "Too many year digits!");
        } else if( year < 1900 ) {
            return this.retError("year", birthday
            , "You cannot be over 120 years' old! Is this a joke?");
        } 

        // Date(_, monthIndex, _) - notice it's monthIndex starts from 0.
        var threshold = new Date(parseInt(year) + 13, parseInt(month)-1, parseInt(day));
        var cmpDate = Date.parse(threshold.toString());
        console.log("threshold:", threshold, " cmpDate:", cmpDate, " now:", Date.now());
        if(Date.now() < cmpDate ) {
            return this.retError("year", birthday, "You have to be 13 or older!");
        }
        // if( year > maxYear ) {
        //     return this.retError("year", birthday, "You cannot be youger than 13!");
        // }
        return [birthday
            , null
            , "success"];
    }

    render() {
        var isUsPhone = this.props.phoneValue.startsWith("+1");
        // If the phone is a US phone (+1), it uses MM/dd/yyyy format,
        // else it uses yyyy-MM-dd ISO format.
        return <FormGroup>
            <Form.Text>Birthday:</Form.Text>
            <InputGroup className="mb-0">
            
            <InputGroup.Prepend>
            <InputGroup.Text>
             <FontAwesomeIcon icon="anchor" />
             </InputGroup.Text>
          </InputGroup.Prepend>

            {isUsPhone &&
            <FormControl
                type="input"
                value={this.state.monthValue}
                name="month"
                onChange={this.handleMonthChange}
                onBlur={this.handleMonthBlur}
                autoComplete="month"
                placeholder="MM"
                isInvalid={this.state.monthState === "error"}
                isValid={this.state.birthdayState === "success"}
                required />}
            {!isUsPhone && 
             <FormControl
                type="input"
                value={this.state.yearValue}
                name="year"
                onChange={this.handleYearChange}
                onBlur={this.handleYearBlur}
                autoComplete="year"
                placeholder="yyyy"
                isInvalid={this.state.yearState === "error"}
                isValid={this.state.birthdayState === "success"}
                required />}

            <InputGroup.Prepend>
              { isUsPhone && <InputGroup.Text>/</InputGroup.Text>}
              {!isUsPhone && <InputGroup.Text>-</InputGroup.Text>}
            </InputGroup.Prepend>

            {isUsPhone &&
            <FormControl id="signinDayId"
                type="input"
                value={this.state.dayValue}
                name="day"
                onChange={this.handleDayChange}
                onBlur={this.handleDayBlur}
                autoComplete="day"
                placeholder="dd"
                isInvalid={this.state.dayState === "error"}
                isValid={this.state.birthdayState === "success"}
                required />}
            {!isUsPhone && 
            <FormControl
                type="input"
                value={this.state.monthValue}
                name="month"
                onChange={this.handleMonthChange}
                onBlur={this.handleMonthBlur}
                autoComplete="month"
                placeholder="MM"
                isInvalid={this.state.monthState === "error"}
                isValid={this.state.birthdayState === "success"}
                required />}
            
            <InputGroup.Prepend>
              {isUsPhone === true && <InputGroup.Text>/</InputGroup.Text>}
              {isUsPhone !== true && <InputGroup.Text>-</InputGroup.Text>}
            </InputGroup.Prepend>

            {isUsPhone && 
            <FormControl
                type="input"
                value={this.state.yearValue}
                name="year"
                onChange={this.handleYearChange}
                onBlur={this.handleYearBlur}
                autoComplete="year"
                placeholder="yyyy"
                isInvalid={this.state.yearState === "error"}
                isValid={this.state.birthdayState === "success"}
                required />}
            {!isUsPhone && 
            <FormControl id="signinDayId"
                type="input"
                value={this.state.dayValue}
                name="day"
                onChange={this.handleDayChange}
                onBlur={this.handleDayBlur}
                autoComplete="day"
                placeholder="dd"
                isInvalid={this.state.dayState === "error"}
                isValid={this.state.birthdayState === "success"}
                required />}
        
            </InputGroup>
            <FormControl.Feedback />
            {this.state.birthdayError != null 
                && <Form.Text style={styles.helpBlock}>* {this.state.birthdayError}
                   </Form.Text>}
        </FormGroup>
    }
}