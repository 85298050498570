
export const styles = {
    blankHelpBlock: {
        'marginBottom': '25px'
    }
    ,helpBlock:{
        'marginTop': '2px'
        ,'marginBottom': '3px'
        ,'color': 'red'
    }
    ,formGroup: {
        'marginBottom': '0'
    }
    ,modalBodyStyle: {
        'marginLeft':'30px'
        ,'marginRight':'30px'
    }
    ,modalFooterStyle: {
        'marginLeft': '-15px'
        ,'marginRight': '-15px'
    }
    ,hideIt: {
        'display':'none'
    }
    ,imageStyle: {
        'width': '200px'
        ,'height': '50px'
        ,'marginTop': '0px'
    }
    ,avwidth: {
        'width': '100%'
    }
};


