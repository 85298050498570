import React, {Component} from 'react'
import {Form
    , FormGroup
    , FormControl
    , InputGroup
    //, FormLabel
    , FormText } from 'react-bootstrap'
import {styles} from './styles.js'

import {library} from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faCheckDouble } from '@fortawesome/free-solid-svg-icons';

// Password icon can be key, or checkDouble fa icon.
library.add(faKey, faCheckDouble);

export default class PasswordControl extends Component {
    constructor() {
        super();
        this.handlePasswordBlur = this.handlePasswordBlur.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.setConfirmToPassword = this.setConfirmToPassword.bind(this);
        this.state = {
             passwordValue: ''
            ,passwordError: null
            ,passwordState: null
        }
    }

    handlePasswordBlur(e){
        var password = e.target.value;
        var retArr = this.evalPassword(password);
        this.uplinkPassword(retArr);
    }
    
    uplinkPassword(inArr){
        this.setState({
            passwordValue: inArr[0]
            ,passwordError: inArr[1]
            ,passwordState: inArr[2]
        });
        this.props.onBlur({fieldName: this.props.name
            , fieldValue: inArr[0]
            , fieldError: inArr[1]
            , fieldState: inArr[2]});
    }

    handlePasswordChange(e){
        //var password= e.target.value;
        this.setState({passwordError: null, passwordState: null});
    }

    retError(pw, errStr) {
        return [pw
            , errStr
            , "error"];
    }
    evalPassword(inPw){
        var minPwLen = 8;

        var otherPw = this.state.confirmToPassword;
        console.log("otherPw:", otherPw);
        if(inPw === "") {
            return this.retError(inPw, "Password cannot be blank!");
        } else if (otherPw !== undefined && otherPw !== inPw){
            return this.retError(inPw, "The passwords doesn't match!");
        } else if ( inPw.match(/\d/g) === null 
                 || inPw.match(/\d/g).length === 0){
            return this.retError(inPw, "It needs number(s).");
        } else if ( inPw.match(/[A-Z]/g) === null 
                 || inPw.match(/[A-Z]/g).length === 0 ){
            return this.retError(inPw, "It needs uppercase letters.");
        } else if ( inPw.match(/[a-z]/g) === null
                 || inPw.match(/[a-z]/g).length === 0 ){
            return this.retError(inPw, "It needs lowercase letters.");
        } else if (inPw.length < minPwLen) {
            return this.retError(inPw, "It requiress at least " 
                + minPwLen.toString() + " chars!");
        }

        // If it gets here, it has passed the validation.
        return [ inPw
            ,null
            ,"success"
        ];
    }

    setConfirmToPassword(inPw){
        this.setState({confirmToPassword: inPw});
    }

    render() {
        if( this.props.onConfirmToPassword !== undefined ){
            this.props.onConfirmToPassword(this.setConfirmToPassword);
        }
        return <FormGroup controlId={this.props.controlId}>
            <Form.Text>{this.props.fieldDescription}</Form.Text>

        <InputGroup className="mb-0">
            <InputGroup.Prepend>
            <InputGroup.Text>
             <FontAwesomeIcon icon={this.props.iconName} />
             </InputGroup.Text>
          </InputGroup.Prepend>
            <FormControl type="password" 
                onBlur={this.handlePasswordBlur}
                onChange={this.handlePasswordChange}
                name={this.props.name} 
                autoComplete={this.props.autoComplete}
                placeholder={this.props.placeholder}
                isInvalid={this.state.passwordState === "error"}
                isValid={this.state.passwordState === "success"}
                required />
        </InputGroup>
            <FormControl.Feedback/>
            {this.state.passwordError != null && <FormText style={styles.helpBlock}>* {this.state.passwordError}</FormText>}
        </FormGroup>
    }
}